import React from 'react'

const AuthLayout = ({children}) => {
    return (
        <main className="container-fluid w-100" role="main">
			<div className="row">
                {children}
            </div>
        </main>
    )
}

export default AuthLayout
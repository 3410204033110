import React from 'react'
import Layout from '../Components/Layout'

const Support = () => {
    return (
        <div>
            <Layout page={"support"}>

            </Layout>
        </div>
    )
}

export default Support
import React, { useState} from 'react'
import Webcam from 'react-webcam'
import axios from 'axios'

import { withRouter } from 'react-router-dom'

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user',
}

const SelfieInfo = ({history}) => {
    const [picture, setPicture] = useState('');
    const [selfie, setSelfie] = useState("");

    const webcamRef = React.useRef(null)

    const capture = React.useCallback( async() => {
        const pictureSrc = webcamRef.current.getScreenshot()
        setPicture(pictureSrc)
        
        const formData = new FormData();
        formData.append("file", pictureSrc);
        formData.append("upload_preset","cr5ikfwq");
        formData.append("cloud_name","depfhww7y");

        const { data } = await axios.post("https://api.cloudinary.com/v1_1/depfhww7y/upload", formData);
        setSelfie(data.url);
    },[])
       
    //Get user ID from URL
    let newsUrl = window.location.href,
    newsSplit = newsUrl.split('/'),
    userId = newsSplit[newsSplit.length-1];

    //Submit Upload the image and submit the data
    const handleSubmit = async () => {

        const config = {
            headers:{
                "Content-Type":"application/json"
            }
        }

        try {
            //Upload the imag to cloudinary
           
            if(selfie!==""){
                const body = {
                    selfie
                }
                //save Uploaded Image Url to Database
                const send = await axios.put("/api/user/selfie/"+userId, body, config);
                if(send){
                    sessionStorage.setItem("selfie", selfie)
                    history.push("/step/completed")
                    // console.log(send);
                }
            }
            
        } catch (err) {
            console.log(err.message)
        }
    }

    const handlePev = () =>{
        history.goBack()
    }

    return (
        <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center bg-white mnh-100vh pt-5">
            

            <div className="u-login-form my-auto">
                    <div className="mb-1 text-center">
                        <h1 className="h2">Selfie Identification</h1>
                    </div>

                    <div className="mb-1 text-center">
                    {picture === '' ? (
                    <Webcam
                        audio={false}
                        height={400}
                        ref={webcamRef}
                        width={400}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                    />
                    ) : (
                    <img src={picture} alt="Captured" />
                    )}
                    </div>		
                    
                    <div className="text-center">
                    {picture !== '' ? (
                        <button
                            onClick={(e) => {
                            e.preventDefault()
                            setPicture("")
                            }}
                            className="btn btn-primary mb-2"
                        >
                            Retake
                        </button>
                        ) : (
                        <button
                            onClick={(e) => {
                            e.preventDefault()
                            capture()
                            }}
                            className="btn btn-danger"
                        >
                            Capture
                        </button>
                        )}
                    </div>
                    
                    {
                        selfie &&
                        <div className="d-flex justify-content-between">
                            <button  className="btn btn-primary px-5" onClick={ handlePev } type="button">Prev</button>
                            <button className="btn btn-primary px-5" onClick={handleSubmit}>Next</button>
                        </div>
                    }
            </div>
            <a className="u-login-form py-3 " href="/">
            Powered by 
                <img 
                    className="img-fluid pl-2" 
                    src="../img/logo.svg" 
                    width="110" 
                    alt="Stream Dashboard UI Kit" 
                />
            </a>
            <div className="u-login-form text-muted  mt-auto">
                <small><i className="far fa-question-circle mr-1"></i> If you are not able to sign up, please <a href="/contact">contact us</a>.</small>
            </div>
      </div>

    )
}

export default withRouter(SelfieInfo)
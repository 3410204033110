import axios from "axios"
import { useState } from "react"





const ExportCard = () => {
    const [loading, setLoading] = useState(false)

    const exportApproved = async () => {
        setLoading(true)
        try {
            const config = {
                headers:{
                    "Content-type":"application/json",
                    "x-auth-token":localStorage.getItem("x-auth-token")
                }
            }
    
    
            const { data } = await axios.get("http://localhost:5000/api/user/approved/all", config)
    
            // Convert the data objects into rows of CSV text
            const rows = data.map(d => {
                return Object.values(d).join(',');
            });
    
            // Prepend the column names as the first row
            const columnNames = Object.keys(data[0]);
            rows.unshift(columnNames.join(','));
    
            // Join the rows into a single CSV string
            const csv = rows.join('\n');
    
            // Create a hidden link element and click it to trigger the download
            const link = document.createElement('a');
            link.style.display = 'none';
            link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
            link.setAttribute('download', 'data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false)
        } catch (err) {
            console.log(err)
        }    
    }

    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className="row">
                   
                    <div className="col-md-4 mx-auto">
                        <button className='btn btn-primary' onClick={exportApproved} 
                            disabled={loading? true: false} >
                            {loading? "Exporting KYC": "Export Approved KYC"}
                        </button>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default ExportCard
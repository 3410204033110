import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'

const Layout = ({children, page}) => {
  return (
    <div>
        <Header />
        <main className="u-main" role="main">
            <Sidebar page={page}/>

            <div className="u-content">
				        <div className="u-body">
                    {children}
                </div>

                <Footer />
            </div>
        </main>
    </div>
  )
}

export default Layout
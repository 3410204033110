import React from 'react'
import BoardLayout from '../Components/BoardLayout'
import IdentityInfo from '../Components/IdentityInfo'

const OnboardTwo = () => {
  return (
    <div>
        <BoardLayout>
            <IdentityInfo />
        </BoardLayout>
    </div>
  )
}

export default OnboardTwo
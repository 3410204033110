import React, { useEffect, useState } from 'react'
import axios from 'axios';

import useStore from '../store/useStore';

const ProAcctTab = ( ) => { 
    const [users, setUsers] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0)

    const user = useStore((state)=>state.user)
    
    let newsUrl = window.location.href,
    newsSplit = newsUrl.split('/'),
    userUrl = newsSplit[newsSplit.length-2];

    const handlePay = async () =>{

        const config = {
            headers:{
                "X-Requested-With":"XMLHttpRequest"
            }
        }

        const body = {
            name: user.name,
            email: user.email,
            amount: totalPrice,
            user: users,
            callback:"http://"+userUrl+"/verify/"+user.email+"/"+users
        }

        try {
            const res = await axios.post("api/payment", body, config)
            const url = res.data.data.authorization_url
            if(res){
                window.location.href = url
            }
             console.log(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    
    const changePrice = async (e) =>{
        setUsers(e.target.value)

        //Get the price in Naira
        const price = 750;

        // console.log(price);
        setTotalPrice(parseInt(price*0.45*e.target.value))
    }

    useEffect(()=>{
        
    }, [])

    return (
        <div className="tab-pane fade " id="panelWithTabsTab1" role="tabpanel">
           <div className="row pt-4">
                <div className="col-lg-12">
                    <table className="table table-responsive">
                        <thead>
                            <tr>
                            <th>Amount($)</th>
                            <th>Due Date</th>
                            <th>Date of Payment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>3000</td>
                                <td>12/04/2023</td>
                           
                                <td>23/02/2023</td>
                            </tr>
                            <tr>
                                <td>3000</td>
                                <td>12/04/2023</td>
                           
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-lg-6">
                    <div className="form-group mb-4">
                        <label htmlFor="city">Pay As you go</label>
                        <input id="city" 
                            className="form-control-range" 
                            type="range" 
                            min={100} max={15000} 
                            onChange={changePrice}
                        />
                    </div>
                </div>
                <div className="col-lg-6"></div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group mb-4">
                        <label htmlFor="state">User / Amount ($0.45 per user)</label><br/>
                        <div>
                            <span 
                                className="badge badge-info p-3"
                                >
                                {"User: "+new Intl.NumberFormat().format(users)}
                            </span>  =  
                            <span 
                                className="badge badge-success p-3">
                                {"Amount (₦): "+ new Intl.NumberFormat().format(totalPrice) }
                                </span> 
                        </div>
                    </div>
                </div>
                <div className="col-lg-6"></div>
            </div>
                
            <div className="row">
                <div className="col-lg-6">
                    <button 
                        onClick={handlePay} 
                        className="btn btn-default btn-block"
                    >Pay</button>
                </div>
            </div>
           
        </div>
    )
}

export default ProAcctTab;

import Sidebar from "../Admin/Sidebar"
import Header from "./Header"
import Footer from "./Footer"

const AdminLayout = ({children, page}) => {
    return (
        <div>
            <Header />
            <main className="u-main" role="main">
                <Sidebar page={page}/>

                <div className="u-content">
                    <div className="u-body">
                        {children}
                    </div>
                    <Footer />
                </div>
            </main>
        </div>
    )
}

export default AdminLayout
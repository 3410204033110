import React from 'react'

const AuthLeft = () => {
    return (
        <div className="col-lg-6 d-none d-lg-flex 
            flex-column align-items-center 
            justify-content-center bg-light">
            <img 
                className="img-fluid position-relative u-z-index-3 mx-5" 
                src="../img/side.png" 
                alt="Side display of beautify customers" 
            />
        </div>
    )
}

export default AuthLeft
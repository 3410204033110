import React from 'react'
import AuthLayout from '../Components/AuthLayout'
import AuthLeft from '../Components/AuthLeft'
import RegisterForm from '../Components/RegisterForm'

const Register = () => {
  return (
    <div>
        <AuthLayout>
          <RegisterForm />
          <AuthLeft />
	      </AuthLayout>
    </div>
  )
}

export default Register
import React from 'react'
import ApprovedCard from '../Components/ApprovedCard'
import Layout from '../Components/Layout'
// import PendCard from '../Components/PendCard'

const Approve = () => {
    return (
        <div>
            <Layout page={"approved"}>
                <ApprovedCard />
            </Layout>
        </div>
    )
}

export default Approve
import React from 'react'

const Company = () => {
  return (
    <div className='bdy'>
    <nav className="navbar navbar-expand-md navbar-dark bg-dark ">
        <div className="container con">
          <a className="navbar-brand" href="/"><img src="img/logo.svg" width="150" alt='website logo' /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse  order-2" id="navbarCollapse">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/solution">Solution</a>
              </li>
              <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown"  aria-expanded="false">
                Developers
              </a>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="/developer">Developer</a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" data-toggle="dropdown"  aria-expanded="false">
                Company
              </a>
              <div className="dropdown-menu" >
                <a className="dropdown-item active" href="/company">Company</a>
              </div>
            </li>
            </ul>
              <a href='/register' className="btn bt btn-outline-success my-2 my-sm-0" >Create an Account</a>
              <a href='/login' className="btn bt btn-outline-success my-2 my-sm-0"  alt="Login"><i className='fa fa-lock'></i></a>
          </div>
      </div>
    </nav>

    <main role="main">
        <div className="heru py-5 " >
          <div className="row">
            <div className="container con text-center my-auto">
                <h1 className="h1 mb-4" style={{ color: "#000"}}>Company</h1>
            </div>
          </div>
        </div>

        <div className="know pb-5">
          <div className="container con text-center">
            <div className="row">
                <div className="row p-3 mx-auto">
                    <img src="img/play.png" className="img-fluid" width="700" alt="Hand Lens" />
                </div>
              </div>   
            </div>
            <div className="text-center mx-auto" style={{ maxWidth:"700px"}}>
              <h1 className="h1">Why SoftKYC</h1>
              <p className="mb-4">AI and Human powered identity verification solution to enhance an inclusive relationship between you and your customers. SoftKYC Identity Platform helps you reduce the cost of customer acquisition, onboard new customers, detect fraud, and satisfy KYC, and other global compliance requirements.</p>
              <button className="btn bt btn-primary px-5"> Let us know</button>
            </div>
        </div>
        <div className="vision">
            <div className="container con">
              <div className="row mx-auto">
                <div className="col-md-5">
                    <img src="img/PersonImage.png" width="250" alt="Vision " />
                </div>
                <div className="col-md-7">
                    <h2 className='h2'>Vision</h2>
                    <p>AI and Human powered identity verification solution to enhance an inclusive relationship between you and your customers. SoftKYC Identity Platform helps you reduce the cost of customer </p>
                    <h3 className='h2 pt-3'>Mission</h3>
                    <p>AI and Human powered identity verification solution to enhance an inclusive relationship between you and your customers.</p>
                </div>
              </div>
            </div>
        </div>

        <div className="carrier">
            <div className="container con">
              <div className="row mx-auto">
                <div className="col-md-7">
                    <div className=" p-5">
                        <span>Carrier</span>
                        <h2 className='h2'>Start Your adventure</h2>
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="Enter Email"  />
                          
                            <button className="btn bt btn-primary ml-4" type="button">Create an Account</button>
                        
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <img src="img/carrierimg.png" width="350" alt="Carrier" />
                </div>
              </div>
            </div>
        </div>

        <div style={{ backgroundColor: "#000000" }} className="pt-5 pb-5">
              <div className="container con">
                <div className="row">
                  <div className="col-md-10 mx-auto card p-4">
                    <div className="row">
                      <div className="col-lg-8">
                        <h1 className="h1" style={{ color: "#000" }}>Single API</h1>
                        <p>Integrate the SoftKYC Identity verification platform with a single API. Get started quickly using our guides and reference materials. </p>
                      </div>
                      <div className="col-lg-4 my-auto">
                        <button className="btn bt btn-primary">API Reference</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

        <div style={{ backgroundColor: "#000000" }}>
              <div className="container con py-4 mb-0" >
                <div className="row">
                  <div className="col-md-3">
                    <img src="img/logo.svg" alt='bottom logo' />
                    <a href="mail:support@softkyc.com">support@softkyc.com</a>
                  </div>
                  <div className="col-md-2" >
                    <h4>Links</h4>
                    <a href="home.html">Home</a><br/>
                    <a href="home.html">Solution</a><br/>
                    <a href="developer.html">Developer</a><br/>
                    <a href="company.html">Company</a><br/>
                    <a href="career.html">Career</a>
                  </div>
                  <div className="col-md-3">
                    <h4>Help & Support</h4>
                    <a href="faq.html">FAQ</a><br/>
                    <a href="contact.html">Contact</a>
                  </div>
                  <div className="col-md-4">
                    <h4>Connect</h4>
                    <img src="img/linkedin.png" alt='Linkedin social icon' />
                    <img src="img/twitter.png" alt='twitter social icon' />
                    <img src="img/instagram.png" alt='Instagram social icon' />
                    <br/>

                    <div className="input-group mt-3">
                  <input type="text" className="form-control" placeholder="Email Address" aria-label="Email Address" aria-describedby="basic-addon2" />
                  <div className="pl-1">
                    <button className="btn bt btn-outline-secondary" type="button">Subscribe</button>
                  </div>
                </div>
                  </div>
                </div>
              </div>
              <div className="container con text-center py-5">
                <p>&copy; Copyright { new Date().getFullYear() } SoftKYC Technologies.</p>
              </div>
            </div>
    </main>
  </div>
  )
}

export default Company
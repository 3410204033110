import axios from 'axios'
import { useEffect, useState } from 'react'
import AdminLayout from '../Components/AdminLayout'


const AdminBuss = () => {
    const [clients, setClients ] = useState(null)
    const [client, setClient] = useState("")
    const [limit, setLimit ] =useState(6)

    const [total, setTotal]     = useState(null)
    const [pending, setPending] = useState(null)
    const [approve, setApprove] = useState(null)
    const [decline, setDecline] = useState(null)

    const [loading, setLoading] = useState(false)

    let id = 0


    const handleMore = () =>{
        setLimit(limit+5)
    }

    const viewUser = (id) => {
        //filter 
        setLoading(true)
        clients.forEach((client)=>{
            if(client._id===id)
            setClient(client)
        })

        getPending(id.toString())
        getAll(id.toString())
        getApproved(id.toString())
        getRejected(id.toString())
       
    }

    const getPending = async (id) =>{
        
        try {
            if(id==="63557649a7b2e4a69f30c039"){
                const {data} = await axios.get("https://api.fusionxperience.io/api/kyc/fetchall")
                if(data.data){
                    let allData = data.data
                    let records = allData.filter((data)=>data.status==="pending")
                    // console.log(record);
                    setPending(records.length)
                }
            }else{
                const config = {
                    headers:{
                        "Content-Type":"application/json",
                        "x-auth-token":localStorage.getItem("x-auth-token")
                    }
                }
                const { data } = await axios.get(`http://localhost:5000/api/user/all/${id}`, config)
                if(data){
                    let allData = data
                    let records = allData.filter((data)=>data.status==="pending")
                    // console.log(record);
                    setPending(records.length)
                    console.log(records.length)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }
   


    const getAll = async (id) =>{
        try {
            if(id==="63557649a7b2e4a69f30c039"){
                const {data} = await axios.get("https://api.fusionxperience.io/api/kyc/fetchall")
                if(data.data){
                    let allData = data.data
                    let total = allData.length;
                    setTotal(total)
                }
            }else{
                const config = {
                    headers:{
                        "Content-Type":"application/json",
                        "x-auth-token":localStorage.getItem("x-auth-token")
                    }
                }

                const { data } = await axios.get(`http://localhost:5000/api/user/all/${id}`, config)
                if(data){
                    
                    let records = data
                    setTotal(records.length)
                    console.log(records.length)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }
    

    const getApproved = async (id) =>{
        try {
            if(id==="63557649a7b2e4a69f30c039"){
                const {data} = await axios.get("https://api.fusionxperience.io/api/kyc/fetchall")
                if(data.data){
                    let allData = data.data
                    let records = allData.filter((data)=>data.status==="approved")
                    // console.log(record);
                    let total = records.length
                    setApprove(total)
                }
            }else{
                const config = {
                    headers:{
                        "Content-Type":"application/json",
                        "x-auth-token":localStorage.getItem("x-auth-token")
                    }
                }

                const { data } = await axios.get(`http://localhost:5000/api/user/all/${id}`, config)
                if(data){
                    let records = data.filter((data)=>data.status==="approved")
                    // console.log(record);
                    setApprove(records.length)
                    console.log(records.length)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }
    

    const getRejected = async (id) =>{
        try {
            if(id==="63557649a7b2e4a69f30c039"){
                const {data} = await axios.get("https://api.fusionxperience.io/api/kyc/fetchall")
                if(data.data){
                    let allData = data.data
                    let records = allData.filter((data)=>data.status==="rejected")
                    // console.log(record);
                    let total = records.length
                    setLoading(true)
                    setDecline(total)
                    setLoading(false)
                }
            }else{
                const config = {
                    headers:{
                        "Content-Type":"application/json",
                        "x-auth-token":localStorage.getItem("x-auth-token")
                    }
                }

                const { data } = await axios.get(`http://localhost:5000/api/user/all/${id}`, config)
                if(data){
                    let records = data.filter((data)=>data.status==="decline")
                    setLoading(true)
                    setDecline(records.length)
                    console.log(records.length)
                    setLoading(false)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }
    


    useEffect(()=>{
        const getAllClient = async () =>{
            const config = {
                headers:{
                    "Content-Type":"application/json",
                    "x-auth-token":localStorage.getItem("x-auth-token")
                }
            }

            try {
                const { data } = await axios.get(`/api/client/all/${limit}`, config)
                setClients(data)
            } catch (err) {
                console.log(err)
            }
        }

        getAllClient()
    }, [limit])

    return (
        <AdminLayout page="business">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <header className="card-header">
                            <h2 className="h3 card-header-title">List of Business </h2>
                        </header>
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th className="text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        clients && clients.map((client)=>(
                                            <tr key={client._id}>
                                                <td>{id=id+1}</td>
                                                <td>{ client.name }</td>
                                                <td>{ client.email }</td>
                                            
                                            
                                                <td className="text-center">
                                                    <button className="btn btn-sm btn-primary mr-2" >
                                                        <i className="fa fa-pause"></i>
                                                    </button>
                                                    <button className="btn btn-sm btn-primary" onClick={()=>viewUser(client._id)} >
                                                        <i className="fa fa-eye"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <button className="btn btn-block btn-primary mt-3" onClick={handleMore}> More </button>
                </div>
                <div className="col-md-6 float">
                    <div className="card mb-2 ">
                        <header className="card-header">
                            <h2 className="h3 card-header-title">Business Details </h2>
                        </header>

                        <div className="card-body">
                            <div className="row"> 
                                <div className='col-md-7'>
                                    { (client.name) && 
                                        <>
                                        <span className='small text-uppercase'>Name</span>
                                        <div style={{fontWeight:600}}>{client.name}</div>
                                        </>
                                    }
                                    { (client.email) && 
                                        <>
                                        <span className='small text-uppercase'>Email</span>
                                        <div style={{fontWeight:600}}>{client.email}</div>
                                        </>
                                    }
                                    {
                                        (client.mobile) &&
                                        <>
                                        <span className='small text-uppercase'>Mobile</span>
                                        <div style={{fontWeight:600}}>{client.mobile}</div>
                                        </>
                                    }
                                    {
                                        (client.slots) &&
                                        <>
                                        <span className='small text-uppercase' >Slots</span>
                                        <div style={{fontWeight:600}}>{client.slots}</div>
                                        </>
                                    }

                                    
                                    
                                </div>
                                <div className='col-md-5'>
                                    {
                                        (client.logo) && <img src={client.logo} alt="passport" width={150} height={150} />
                                    }
                                </div> 
                                
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className='col-md-4'>
                                    
                                    { 
                                        client.businessName && <>
                                        <span className='small text-uppercase'>Business Name</span>
                                        <div style={{fontWeight:600}}>{client.businessName}</div> 
                                        </>
                                    }                               
                                    
                                </div>
                                <div className='col-md-4'>
                                    
                                    { 
                                        client.businessCity && <>
                                        <span className='small text-uppercase'>Business City</span>
                                        <div style={{fontWeight:600}}>{client.businessCity}</div> 
                                        </>
                                    }                               
                                    
                                </div>
                                <div className='col-md-4'>
                                    
                                    { 
                                        client.country && <>
                                        <span className='small text-uppercase'>Country</span>
                                        <div style={{fontWeight:600}}>{client.country}</div> 
                                        </>
                                    }                               
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                    {
                        pending && 
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className='col-md-3'>
                                        <h5 className="h6 text-muted text-uppercase mb-2">
                                            Pending <i className="fa fa-arrow-up text-success ml-1"></i>
                                        </h5>
                                        <span className="h4 mb-0">{new Intl.NumberFormat().format(pending)}</span>
                                    </div>
                                    <div className='col-md-3'>
                                        <h5 className="h6 text-muted text-uppercase mb-2">
                                            Approved <i className="fa fa-arrow-up text-success ml-1"></i>
                                        </h5>
                                        <span className="h4 mb-0">{new Intl.NumberFormat().format(approve)}</span>
                                    </div>
                                    <div className='col-md-3'>
                                        <h5 className="h6 text-muted text-uppercase mb-2">
                                            Declined <i className="fa fa-arrow-up text-success ml-1"></i>
                                        </h5>
                                        <span className="h4 mb-0">{new Intl.NumberFormat().format(decline)}</span>
                                    </div>
                                        
                                    <div className='col-md-3'>
                                        <h5 className="h6 text-muted text-uppercase mb-2">
                                            Amount
                                        </h5>
                                        <span className="h4 mb-0">{"$ "+new Intl.NumberFormat().format(total*0.40)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    
                    {
                        // !decline && <image src="" alt="loading" />

                        loading && <h2>Loading...</h2>
                    }
                </div>
            </div>
            
        </AdminLayout>
    )
}

export default AdminBuss
import React from 'react'


const Landing = () => {


  return (
    <div className='bdy'>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark ">
          <div className="container con">
            <a className="navbar-brand" href="/"><img src="img/logo.svg" width="150" alt='website logo' /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse  order-2" id="navbarCollapse">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/solution">Solution</a>
                </li>
                <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown"  aria-expanded="false">
                  Developers
                </a>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="/developer">Developer</a>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" data-toggle="dropdown"  aria-expanded="false">
                  Company
                </a>
                <div className="dropdown-menu" >
                  <a className="dropdown-item" href="/company">Company</a>
                </div>
              </li>
              </ul>
                <a href='/register' className="btn bt btn-outline-success my-2 my-sm-0" >Create an Account</a>
                <a href='/login' className="btn bt btn-outline-success my-2 my-sm-0"  alt="Login"><i className='fa fa-lock'></i></a>
            </div>
        </div>
      </nav>

      <main role="main">
          <div className="container con m" >
            <div className="row">
              <div className="col-lg-6 my-auto">
                <div className="">
                  <h3 className="h1">The easiest Identity Verification Service in the world</h3>
                  <p style={{textAlign: "justify", color: "#fff"}}>AI and Human powered identity verification solution to enhance an inclusive relationship between you and your customers.
SoftKYC Identity Platform helps you reduce the cost of customer acquisition, onboard new customers, detect fraud, and satisfy KYC, and other global compliance requirements.</p>
                </div>

                <div className="row">
                <div className="text-left">
                  <button className="btn bt btn-primary mr-2">Start now</button>
                  <button className="btn bt btn-primary">Learn more</button>
                </div>        				
                </div>
              </div>
              <div className="col-lg-6">
                <img src="img/header_img.png" className="img-fluid" style={{maxHeight:"600px"}} alt="Header"/>	
              </div>
            </div>

            <div className="row">
              <img src="img/banner.png" className="img-fluid" alt="Banner" />
            </div>
          </div>

          <div className="know">
            <div className="container con text-center">
              <h1 className="h1 mb-4" >The total solution to know and trust your customers online.</h1>

              <p className="mb-5">Discover the softest KYC platform, best suited for early stage startups & teams. </p>


              <div className="row">
                <div className="card col-md-12 mb-3">
                  <div className="row p-3">
                    <div className="col-md-4">
                      <img src="img/handlens.png" className="img-fluid" width="230" alt="Hand Lens" />
                    </div>
                    <div className="col-md-8 text-left my-auto">
                      <div className="pl-5">
                        <h2>SoftKYC ID Verification</h2>
                        <p style={{color: "#000", fontSize:"18px", paddingRight: "40px"}}>
                        Determine if an ID is authentic or has been manipulated. Real time friction-free identity verification powered by both humans and AI.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card col-md-12 mb-3">
                  <div className="row p-3">
                    <div className="col-md-4">
                      <img src="img/ballseye.png" className="img-fluid" width="230" alt="balls eye" />
                    </div>
                    <div className="col-md-8 text-left my-auto">
                      <div className="pl-5">
                        <h2>Fast Implementation</h2>
                        <p style={{ color: "#000", fontSize: "18px", paddingRight: "40px" }}>Quickly integrates into your website or mobile app and lets your users shift from a desktop to mobile experience as desired for fast customer onboarding.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card col-md-12 mb-3">
                  <div className="row p-3">
                    <div className="col-md-4">
                      <img src="img/database.png" className="img-fluid" width="230" alt='Database' />
                    </div>
                    <div className="col-md-8 text-left my-auto">
                      <div className="pl-5">
                        <h2>Soft costing</h2>
                        <p style={{ color: "#000", fontSize: "18px", paddingRight: "40px" }}>The most cost effective KYC platform in the world. Take advantage of our pay as-you-go plan. </p>
                      </div>
                    </div>
                  </div>
                </div>   
              </div>
              <div className="text-center py-5">
                <h1 className="h1 mb-4">The total solution to know and trust your customers online.</h1>
                <button className="btn bt btn-primary bt px-5"> Let us know</button>
              </div>

            </div>
          </div>

          <div style={{ background: "#000000" }}>
            <div className="text-center">
            <h1 className="h1 mb-4">Identity verification made simple</h1>
          </div>
          </div>

          <div className="simple">
              <div className="container con py-5" style={{ position: "relative" }}>
                            
                <div className="row">
                  <div className="card col-md-12 mb-3 mt-3">
                    <div className="row p-3">
                      <div className="col-md-4">
                      </div>
                      <div className="col-md-8 text-left my-auto p-5">
                        <div className="pl-5">
                          <h2>4,000,000+  trusted by thousands of customers</h2>
                          <p style={{ color: "#000", fontSize: "18px"}}>Our document and biometric verifications supports over 2,500 document types in over 200 countries. Confidently verify customer identities using a portfolio of global identity verifications and fraud detection signals.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src="img/screen.png" className="screen img-fluid visible-sm-block hidden-sm" alt='Right Iphone 12' />
                </div>
              </div>

            <div className="container con py-5" style={{ position: "relative" }}>

              <div className="row">
                <div className="card col-md-12 mb-3 mt-3">
                  <div className="row p-3">
                    <div className="col-md-8 text-left my-auto p-5">
                      <div className="pl-5">
                        <h2>1000+  trusted by thousands of startups</h2>
                        <p style={{ color: "#000", fontSize: "18px" }}>Our next generation biometric verification featuring 100% automated analysis and an intuitive capture experience. It’s simple for users, tough for fraudsters.</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                    </div>
                  </div>
                </div>
                <img src="img/iphone2.png" className="screen2 img-fluid" alt='Left Iphone 12' />
              </div>
            </div>

          </div>

          <div className="container con py-5">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card p-4">
                    <div className="row">
                      <div className="col-lg-8">
                        <h1 className="h1" style={{ color: "#000" }}>Single API</h1>
                        <p>Integrate the SoftKYC Identity verification platform with a single API. Get started quickly using our guides and reference materials. </p>
                      </div>
                      <div className="col-lg-4 my-auto">
                        <button className="btn bt btn-primary">API Reference</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div style={{ backgroundColor: "#000000", color:"#fff" }}>
            <div className="container con py-4 mb-0" >
              <div className="row">
                <div className="col-md-3">
                  <img src="img/logo.svg" alt='bottom logo' />
                  <a href="mail:support@softkyc.com">support@softkyc.com</a>
                </div>
                <div className="col-md-2" >
                  <h4>Links</h4>
                  <a href="/">Home</a><br/>
                  <a href="/solution">Solution</a><br/>
                  <a href="/developer">Developer</a><br/>
                  <a href="/company">Company</a><br/>
                </div>
                <div className="col-md-3">
                  <h4>Help & Support</h4>
                  <a href="faq.html">FAQ</a><br/>
                  <a href="contact.html">Contact</a>
                </div>
                <div className="col-md-4">
                  <h4>Connect</h4>
                  <img src="img/linkedin.png" alt='Linkedin social icon' />
                  <img src="img/twitter.png" alt='twitter social icon' />
                  <img src="img/instagram.png" alt='Instagram social icon' />
                  <br/>

                  <div className="input-group mt-3">
                <input type="text" className="form-control" placeholder="Email Address" aria-label="Email Address" aria-describedby="basic-addon2" />
                <div className="pl-1">
                  <button className="btn bt btn-outline-secondary" type="button">Subscribe</button>
                </div>
              </div>
                </div>
              </div>
            </div>
            <div className="container con text-center py-5">
              <p>&copy; Copyright { new Date().getFullYear() } SoftKYC Technologies.</p>
            </div>
          </div>
      </main>
    </div>
  )
}

export default Landing

import axios from "axios"
import { useEffect, useState } from "react"
import AdminLayout from "../Components/AdminLayout"
const AdminFussion = () => {
    const [users, setUsers] = useState(null)

    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")

    const [email, setEmail] = useState("")
    const [dob, setDob] = useState("")
    const [country, setCountry] = useState("")
    const [selfie, setSelfie] = useState("")

    const [cardType, setCardType] = useState("")
    const [cardFront, setCardFront] = useState("")
    const [cardBack, setCardBack] = useState("")

    

     //View user details
     const viewUser = async (id) => {
     
        try{
            try {

                const oneUser = users.filter((user)=>(user.id===id))    

                
                setFirstName(oneUser[0].firstname)
                setMiddleName(oneUser[0].middlename)
                setLastName(oneUser[0].lastname)
                setEmail(oneUser[0].email)
                setDob(oneUser[0].dob)
                
                setCountry(oneUser[0].country)
                setSelfie(oneUser[0].selfie)

                setCardType(oneUser[0].cardType)
                setCardFront(oneUser[0].document_front)
                setCardBack(oneUser[0].document_back)
                

                // setIssuerCountry(oneUser.issuerCountry);
                // setCardExp(oneUser.cardExp);

                console.log(oneUser[0].firstname);
            } catch (err) {
                console.log(err);
            }
        }catch(err){
            console.log("Error Check")
        }
    }

    //Verify User
    const verifyUser = async (id) => {

        const config = {
            headers:{
                "Content-Type":"application/json"
            }
        };

        const body ={
            uuid: id,
            status: "approved"
        }


        try {
            const {data} = await axios.post("https://api.fusionprotocol.io/api/kyc/updateKyc", body, config);
            const ver = data.data

            if(ver){
                const newUser = users.filter((user)=>{
                    return user.uuid !== id;
                });

                setUsers(newUser);

                console.log()
            }
        } catch (err) {
            console.log(err);
        }
    }

  

    //Load more uses
    // const moreUser = () => {
    //     setSeed(seed+4);
    // }

    useEffect(()=>{
        const getData = async () =>{
            try {
                const {data} = await axios.get("https://api.fusionprotocol.io/api/kyc/fetchall")
                if(data.data){
                    // console.log(data.data)
                    setUsers(data.data)
                }
            } catch (err) {
                console.log(err)
            }
        }

        getData()
    }, [])
    
    return (
        <AdminLayout page="fussion">
            <div className="row">
                <div className="col-sm-6" >
                    <div className="row">
                        {
                        users &&  users.map((user)=>(
                            
                            <div className="col-md-6 mb-4" key={user.id}>
                                <div className="card">
                                    <div className="card-body p-3">
                                    <h5 className="h3 card-title text-uppercase">{ user.firstname+" "+user.middlename+" "+user.lastname}</h5>
                                    <h6 className="card-subtitle text-muted mb-3">{user.email}</h6>
                                    <button 
                                        className="btn btn-sm btn-success mr-1"
                                        onClick={()=>verifyUser(user.uuid)}>
                                            <i className="fas fa-check"></i>
                                    </button>
                                    {/* <button 
                                        className="btn btn-sm btn-danger mr-1" 
                                        onClick={()=>declineUser(user._id)}>
                                            <i className="fas fa-ban"></i>
                                    </button> */}
                                    <button 
                                        className="btn btn-sm btn-primary" 
                                        onClick={()=>viewUser(user.id)}>
                                            <i className="fas fa-eye"></i>
                                    </button>
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>


                    <div className="justify-content-between">
                        {/* <button 
                            className="btn btn-block btn-secondary m-1" 
                            onClick={()=>moreUser()}>More
                        </button> */}
                    </div>
                </div>
                <div className="col-sm-6 sticky">
                    <div className="card">
                        <div className="card-header">
                            <h6 className="h3 text-title">User Details</h6>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className='col-md-7'>
                                    { (firstName && lastName) && 
                                        <>
                                        <span className='small text-uppercase'>Name</span>
                                        <div style={{fontWeight:600}}>{firstName+" "+middleName+" "+lastName}</div>
                                        </>
                                    }
                                    { (email) && 
                                        <>
                                        <span className='small text-uppercase'>Email</span>
                                        <div style={{fontWeight:600}}>{email}</div>
                                        </>
                                    }
                                    {
                                        (dob) &&
                                        <>
                                        <span className='small text-uppercase'>Birthday</span>
                                        <div style={{fontWeight:600}}>{dob}</div>
                                        </>
                                    }
                                    {
                                        (country) &&
                                        <>
                                        <span className='small text-uppercase' >Country</span>
                                        <div style={{fontWeight:600}}>{country}</div>
                                        </>
                                    }
                                    
                                </div>
                                <div className='col-md-5'>
                                    {
                                        (selfie) && <img src={selfie} alt="passport" width={150} height={150} />
                                    }
                                </div>
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className='col-md-6'>
                                    
                                    { 
                                        cardType && <>
                                        <span className='small text-uppercase'>Card Type</span>
                                        <div style={{fontWeight:600}}>{cardType}</div> 
                                        </>
                                    }                               
                                    
                                </div>
                                </div>
                            <hr />
                            <div className='row'>
                                <div className='col-md-6'>
                                    {
                                        (cardFront) && <img src={cardFront} alt="Card Front" className='img-fluid' />
                                    }
                                    <a href={cardFront} target="_blanck" className="btn btn-block btn-primary">View Photo</a>
                                </div>
                                <div className='col-md-6'>
                                    { (cardBack) && <img src={cardBack} alt="Card Front" className='img-fluid' /> }
                                    <a href={cardBack} target="_blanck" className="btn btn-block btn-primary">View Photo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AdminFussion
import { useState } from "react"
import axios from "axios"

const ChangePassword = () => {

    const [pass, setPass] = useState("")
    const [nPass, setNPass] = useState("")
    const [cPass, setCPass] = useState("")

    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")

    const handlePassword = async (e) =>{
        e.preventDefault()

        const config = {
            headers:{
                "Content-Type":"application/json",
                "x-auth-token":localStorage.getItem("x-auth-token")
            }
        }

        const body = {
            pass: pass,
            nPass: nPass
        }

        try {
            if(nPass!==cPass){
                setError("New Passwords don't match")
            }else{
                const { data } = await axios.put("/api/client/password", body, config)
                if(data){
                    console.log(data.msg)
                    setSuccess(data.msg)
                }
            }
        } catch (err) {
            console.log(err)
            const { data } = err.response
            setError(data.msg)
        }
    }

    return (
        <div className="col-md-6 mb-4">
            <div className="card">
                <form onSubmit={handlePassword}>
                    <header className="card-header">
                        <h2 className="h3 card-header-title">Change Password</h2>
                    </header>

                    <div className="card-body">
                        <h5 className="h4 card-title">Enter your current and new password</h5>
                        <div className="form-group mb-4">
                            <label htmlFor="pass">Current Password</label>
                            <input id="pass" 
                                className="form-control" type="password" 
                                placeholder="Current Password"
                                value={pass} 
                                onChange={ (e)=> setPass(e.target.value) }
                                />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="nPass">New Password</label>
                            <input id="nPass" 
                                className="form-control" type="password" 
                                placeholder="New Password"
                                value={nPass} 
                                onChange={ (e)=> setNPass(e.target.value) }
                                />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="cPass">Confirm Password</label>
                            <input id="cPass" 
                                className="form-control" type="password" 
                                placeholder="Confirm Password"
                                value={cPass} 
                                onChange={ (e)=> setCPass(e.target.value) }
                                />
                        </div>
                        {
                            error && 
                            <div className="alert alert-danger">
                                { error }
                            </div>
                        }

                        {
                            success && 
                            <div className="alert alert-success">
                                { success }
                            </div>
                        }
                        
                    </div>

                    <footer className="card-footer">
                        <button type="submit" className="btn btn-primary" >Change Password</button>
                    </footer>
                </form>
            </div>
        </div>
    )
}

export default ChangePassword
import React from 'react'
import Layout from '../Components/Layout'
import PasswordCard from '../Components/PasswordCard'

const Password = () => {
    return (
        <Layout page="pending">
            <PasswordCard />
        </Layout>
    )
}

export default Password
import React from 'react'
import Layout from '../Components/Layout'
import ProfileCard from '../Components/ProfileCard'

const Profile = () => {
    return (
        <Layout page={"profile"}>
            <ProfileCard />
        </Layout>
    )
}

export default Profile
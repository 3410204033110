import React from "react";
import { useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

const CardInfo = ({ history }) => {
  const [frontImg, setFrontImg] = useState("../img/front.png");
  const [floading, setFloading] = useState(false);

  const [backImg, setBackImg] = useState("../img/front.png");
  const [bloading, setBloading] = useState(false);
  // const [frontFile, setFrontFile] = useState("")
  // const [backFile, setBackFile] = useState("")

  const [error, setError] = useState("");

  const handleUploadFront = async (e) => {
    const file = e.target.files[0];
    try {
      setFloading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "cr5ikfwq");
      formData.append("cloud_name", "depfhww7y");

      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/depfhww7y/upload",
        formData
      );
      setFrontImg(data.url);
      sessionStorage.setItem("idFront", data.url)
      setFloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUploadBack = async (e) => {
    const file = e.target.files[0];
    try {
      setBloading(true);
      const formData = new FormData();

      formData.append("file", file);
      formData.append("upload_preset", "cr5ikfwq");
      formData.append("cloud_name", "depfhww7y");

      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/depfhww7y/upload",
        formData
      );
      setBackImg(data.url);
      
      setBloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (frontImg === "" || backImg === "") {
      setError("Upload Font and Back Image");
    }

    let newsUrl = window.location.href,
      newsSplit = newsUrl.split("/"),
      userId = newsSplit[newsSplit.length - 1];

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = {
      cardFront: frontImg,
      cardBack: backImg,
    };

    try {
      const { data } = await axios.put(
        "/api/user/idcard/" + userId,
        body,
        config
      );
      history.push("/step4/" + data.id);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePev = () =>{
      history.goBack()
  }

  return (
    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center bg-white mnh-100vh pt-5">
      <div className="u-login-form">
        <form className="mb-3" onSubmit={handleSubmit}>
          <div className="mb-3">
            <h1 className="h2">Identity Card Information</h1>
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="text-center mb-4">
            <div className="brace ">
              <img
                src={floading ? "../img/spinner.gif" : frontImg}
                className="img-fluid"
                style={{ width: "auto" }}
                alt="Front of ID card"
              />
            </div>

            <div className="text-center">
              <input type="file" accept=".png, .jpg, .jpeg" onChange={handleUploadFront} />
            </div>
          </div>

          <div className="text-center mb-4">
            <div className="brace">
              <img
                src={bloading ? "../img/spinner.gif" : backImg}
                className="img-fluid"
                style={{ width: "auto" }}
                alt="Front of ID card"
              />
            </div>

            <div className="text-center">
              <input type="file" accept=".png, .jpg, .jpeg" onChange={handleUploadBack} />
            </div>
          </div>

          <div className="d-flex justify-content-between">
              <button  className="btn btn-primary px-5" onClick={ handlePev } type="button">Prev</button>
              <button className="btn btn-primary px-5" type="submit">Next</button>
          </div>
        </form>
      </div>
      <a className="u-login-form py-3 " href="/">
        Powered by
        <img
          className="img-fluid pl-2"
          src="../img/logo.svg"
          width="110"
          alt="Stream Dashboard UI Kit"
        />
      </a>
      <div className="u-login-form text-muted py-3 mt-auto">
        <small>
          <i className="far fa-question-circle mr-1"></i>
          If you are having troubles with your KYC process
          <a href="/contact">contact us</a>.
        </small>
      </div>
    </div>
  );
};

export default withRouter(CardInfo);

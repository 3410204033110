import React from 'react'
import axios from 'axios';

import { useState, useEffect } from 'react';

const UserCard = () => {

    let [users, setUsers] = useState(null);
    const [seed, setSeed] = useState(4);

    let [firstName, setFirstName]     = useState("");
    let [lastName, setLastName] = useState("");
    let [email, setEmail]   = useState("");
    let [dob, setDob]     = useState("");
    let [country, setCountry]     = useState("");
    let [selfie, setSelfie]     = useState("");

    let [cardType, setCardType] = useState("");
    let [cardIssuer, setCardIssuer] = useState("");
    let [cardFront, setCardFront]   = useState("");
    let [cardBack, setCardBack]     = useState("");
    let [issuerCountry, setIssuerCountry]     = useState("");
    let [cardExp, setCardExp]     = useState("");
    let [createAt, setCreateAt]     = useState("");



    //View user details
    const viewUser = async (id) => {
        const config = {
            headers:{
                "Content-Type":"application/json",
                "x-auth-token":localStorage.getItem("x-auth-token")
            }
        };

        try{
            try {

                const {data } = await axios.get("/api/user/"+id, config);

                setFirstName(data.firstName);
                setLastName(data.lastName);
                setEmail(data.email);
                setDob(data.dob);
                
                setCountry(data.country);
                setSelfie(data.selfie);

                setCardType(data.cardType)
                setCardIssuer(data.cardIssuer)

                setCardFront(data.cardFront);
                setCardBack(data.cardBack);

                setIssuerCountry(data.issuerCountry);
                setCardExp(data.cardExp);
                setCreateAt(data.createAt)

                // console.log(user.data);
            } catch (err) {
                console.log(err);
            }
        }catch(err){
            console.log("Error Check")
        }
    }

    //Verify User
    const verifyUser = async (id) => {

        const config = {
            headers:{
                "Content-Type":"application/json",
                "x-auth-token":localStorage.getItem("x-auth-token")
            }
        };

        const body ={
            status:"approved"
        };

        try {
            const ver = await axios.put("/api/user/"+id, body, config);
            if(ver){
                const newUser = users.filter((user)=>{
                    return user._id !== id;
                });

                setUsers(newUser);
            }
        } catch (err) {
            console.log(err);
        }
    }

    //Decline user
    const declineUser = async (id) => {
        const config = {
            headers:{
                "Content-Type":"application/json",
                "x-auth-token":localStorage.getItem("x-auth-token")
            }
        };

        const body ={
            status:"declined"
        };

        try{
            const ver = await axios.put("/api/user/"+id, body, config);
            if(ver){
                const newUser = users.filter((user)=>{
                    return user._id !== id;
                });

                setUsers(newUser);
            }
        }catch(err){
            console.log(err)
        }
    }

    //Load more uses
    const moreUser = () => {
        setSeed(seed+4);
    }


    useEffect(()=>{
        const config = {
            headers:{
                "Content-Type":"application/json",
                "x-auth-token":localStorage.getItem("x-auth-token")
            }
        };
    
        const getPendingUsers = async( seed ) => {
        
            try {
                const users = await axios.get("/api/user/pending/"+seed, config);
                setUsers(users.data);
            } catch (err) {
                console.log(err);
            }
        }
        getPendingUsers(seed)
    }, [seed]);



    return (
        <div className="row">
            <div className="col-sm-6" >
                <div className="row">
                    {
                    users &&  users.map((user)=>(
                        
                        <div className="col-md-6 mb-4" key={user._id}>
                            <div className="card">
                                <div className="card-body p-3">
                                <h5 className="h3 card-title text-uppercase">{ user.firstName+" "+user.lastName}</h5>
                                <h6 className="card-subtitle text-muted mb-3">{user.email}</h6>
                                <button 
                                    className="btn btn-sm btn-success mr-1"
                                    onClick={()=>verifyUser(user._id)}>
                                        <i className="fas fa-check"></i>
                                </button>
                                <button 
                                    className="btn btn-sm btn-danger mr-1" 
                                    onClick={()=>declineUser(user._id)}>
                                        <i className="fas fa-ban"></i>
                                </button>
                                <button 
                                    className="btn btn-sm btn-primary" 
                                    onClick={()=>viewUser(user._id)}>
                                        <i className="fas fa-eye"></i>
                                </button>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>


                <div className="justify-content-between">
                    <button 
                        className="btn btn-block btn-secondary m-1" 
                        onClick={()=>moreUser()}>More
                    </button>
                </div>
            </div>
            <div className="col-sm-6 float">
                <div className="card">
                    <div className="card-header">
                        <h6 className="h3 text-title">User Details</h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className='col-md-7'>
                                { (firstName && lastName) && 
                                    <>
                                    <span className='small text-uppercase'>Name</span>
                                    <div style={{fontWeight:600}}>{firstName+" "+lastName}</div>
                                    </>
                                }
                                 { (email) && 
                                    <>
                                    <span className='small text-uppercase'>Email</span>
                                    <div style={{fontWeight:600}}>{email}</div>
                                    </>
                                 }
                                {
                                    (dob) &&
                                    <>
                                    <span className='small text-uppercase'>Birthday</span>
                                    <div style={{fontWeight:600}}>{dob}</div>
                                    </>
                                }
                                {
                                    (country) &&
                                    <>
                                    <span className='small text-uppercase' >Country</span>
                                    <div style={{fontWeight:600}}>{country}</div>
                                    </>
                                }
                                
                            </div>
                            <div className='col-md-5'>
                                {
                                    (selfie) && <img src={selfie} alt="passport" width={150} height={150} />
                                }
                                {
                                    (createAt) && <div className='text-center' style={{fontWeight:500}}>{new Date(createAt).toDateString()}</div>
                                }
                            </div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-md-6'>
                                
                                { 
                                    cardType && <>
                                    <span className='small text-uppercase'>Card Type</span>
                                    <div style={{fontWeight:600}}>{cardType}</div> 
                                    </>
                                }                               
                                
                            </div>
                            <div className='col-md-6'>
                               
                                <div>
                                    { 
                                        (cardIssuer) && 
                                        <>
                                            <span className='small text-uppercase'>ID Number</span>
                                            <div style={{fontWeight:600}}> {cardIssuer}</div>
                                        </>
                                         
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                { 
                                    (issuerCountry) && 
                                    <>
                                        <span className='small text-uppercase'>Country of Issue</span>
                                        <div style={{fontWeight:600}}> {issuerCountry}</div>
                                    </>
                                        
                                }
                            </div>
                            <div className='col-md-6'>
                                { 
                                    (cardExp) && 
                                    <>
                                        <span className='small text-uppercase'>Expiration</span>
                                        <div style={{fontWeight:600}}> {cardExp}</div>
                                    </>
                                        
                                }
                            </div>
                        </div>
                        <hr />
                        <div className='row'>
                            <div className='col-md-6'>
                                {
                                    (cardFront) && <img src={cardFront} alt="Card Front" className='img-fluid' />
                                }
                                
                            </div>
                            <div className='col-md-6'>
                                { (cardBack) && <img src={cardBack} alt="Card Front" className='img-fluid' /> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserCard
import { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";

import axios from 'axios';

const PersonInfo = ({history }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");

    const [country, setCountry] = useState("");
    const [error, setError] = useState("")

    const [business, setBusiness] = useState("")

    const formData = {
        firstName: firstName, lastName: lastName, email: email, dob: day+"-"+month+"-"+year, country: country
    };

    var newsUrl = window.location.href,
    newsSplit = newsUrl.split('/'),
    clientId = newsSplit[newsSplit.length-1];

    
    const handleSubmit = async (e) =>{
        e.preventDefault();

        if(firstName===""){
            setError("First name field is required");
        }else if(lastName===""){
            setError("Last name field is required");
        }else if(email===""){
            setError("Email is required");
        }else if(day==="" || month==="" || year===""){
            setError("Date of birth required");
        }else if(country===""){
            setError("Please Select a country")
        }
        
        setTimeout(()=>setError(""), 5000);

        const config = {
            headers:{
                "Content-Type":"application/json"
            }
        };

        const res = await axios.post("/api/user/"+clientId, formData, config)
        
        localStorage.setItem("userId", res.data.id)
        localStorage.setItem("clientId", clientId)

        sessionStorage.setItem("firstName", firstName)
        sessionStorage.setItem("lastName", lastName)
        sessionStorage.setItem("dob", day+"/"+month+"/"+year)
        history.push("/step2/"+res.data.id);
    }

    

    useEffect(()=>{
        const getName = async() => {
            
            const config = {
                headers:{
                    "Content-Type":"application/json"
                }
            }

            try {
                const {data} = await axios.get("/api/client/business/"+clientId, config)
                console.log(data.businessName)
                setBusiness(data.businessName)
            } catch (err) {
                console.log(err)
            }
        }

        getName()
    },[clientId])
    return (
        <>
            
            <div className="col-lg-6 d-flex flex-column 
                justify-content-center align-items-center 
                bg-white mnh-100vh pt-5">
                
                {
                    !business? <img src="../img/spinner.gif" alt="loading" /> : 
                    <>
                        <div className="u-login-form">
                            <form className="mb-3" onSubmit={ handleSubmit }>
                                <div className="mb-3">
                                    <h1 className="h2">Welcome to {business} KYC.</h1>
                                    <p className="medium">Let’s verify your identity</p>
                                </div>
                                { error && <div className="alert alert-danger">{error}</div>}
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label htmlFor="firstName">First Name</label>
                                            <input id="firstName" 
                                                className="form-control" 
                                                name="firstName" type="text"
                                                value={firstName}
                                                onChange = {
                                                    (e)=>setFirstName(e.target.value)
                                                } 
                                                placeholder="First Name" 
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input id="lastName" 
                                                className="form-control" 
                                                name="lastName" 
                                                type="text" 
                                                value={lastName}
                                                onChange = {
                                                    (e)=>setLastName(e.target.value)
                                                }
                                                placeholder="Last Name" 
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <label htmlFor="email">Your email</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="email@domain.com" 
                                        value={ email }
                                        onChange = {
                                            (e)=> setEmail(e.target.value)
                                        }
                                    />
                                </div>

                                <div className="form-group mb-4">
                                    <label htmlFor="email">Date of Birth</label>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <select 
                                                defaultValue={'default'}
                                                className="custom-select" 
                                                id="day" name="day"
                                                onChange={(e)=>setDay(e.target.value)}
                                                >
                                                <option value="default" disabled>Day</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>
                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">20</option>
                                                <option value="30">30</option>
                                                <option value="31">31</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <select 
                                                className="custom-select" 
                                                id="month" name="month"
                                                defaultValue={"default"}
                                                onChange = {
                                                    (e)=>setMonth(e.target.value)
                                                }
                                                >
                                                <option
                                                value="default" disabled >Month</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <select 
                                                className="custom-select" 
                                                id="year" name="year"
                                                defaultValue={"defaults"}
                                                onChange = { 
                                                    (e)=>setYear(e.target.value) 
                                                }
                                            >
                                                <option value="defaults" >Year</option>
                                                <option value="1922">1922</option>
                                                <option value="1923">1923</option>
                                                <option value="1924">1924</option>
                                                <option value="1925">1925</option>
                                                <option value="1926">1926</option>
                                                <option value="1927">1927</option>
                                                <option value="1928">1928</option>
                                                <option value="1929">1929</option>
                                                <option value="1930">1930</option>
                                                <option value="1931">1931</option>
                                                <option value="1931">1932</option>
                                                <option value="1931">1933</option>
                                                <option value="1931">1934</option>
                                                <option value="1931">1935</option>
                                                <option value="1931">1936</option>
                                                <option value="1931">1937</option>
                                                <option value="1931">1938</option>
                                                <option value="1931">1939</option>
                                                <option value="1931">1940</option>
                                                <option value="1931">1941</option>
                                                <option value="1931">1942</option>
                                                <option value="1931">1943</option>
                                                <option value="1931">1944</option>
                                                <option value="1931">1945</option>
                                                <option value="1931">1946</option>
                                                <option value="1931">1947</option>
                                                <option value="1931">1948</option>
                                                <option value="1931">1949</option>
                                                <option value="1931">1950</option>
                                                <option value="1951">1951</option>
                                                <option value="1952">1952</option>
                                                <option value="1953">1953</option>
                                                <option value="1954">1954</option>
                                                <option value="1955">1955</option>
                                                <option value="1956">1956</option>
                                                <option value="1957">1957</option>
                                                <option value="1958">1958</option>
                                                <option value="1959">1959</option>
                                                <option value="1960">1960</option>
                                                <option value="1961">1961</option>
                                                <option value="1962">1962</option>
                                                <option value="1963">1963</option>
                                                <option value="1964">1964</option>
                                                <option value="1965">1965</option>
                                                <option value="1966">1966</option>
                                                <option value="1967">1967</option>
                                                <option value="1968">1968</option>
                                                <option value="1969">1969</option>
                                                <option value="1970">1970</option>
                                                <option value="1971">1971</option>
                                                <option value="1972">1972</option>
                                                <option value="1973">1973</option>
                                                <option value="1974">1974</option>
                                                <option value="1975">1975</option>
                                                <option value="1976">1976</option>
                                                <option value="1977">1977</option>
                                                <option value="1978">1978</option>
                                                <option value="1979">1979</option>
                                                <option value="1980">1980</option>
                                                <option value="1981">1981</option>
                                                <option value="1982">1982</option>
                                                <option value="1983">1983</option>
                                                <option value="1984">1984</option>
                                                <option value="1985">1985</option>
                                                <option value="1986">1986</option>
                                                <option value="1987">1987</option>
                                                <option value="1988">1988</option>
                                                <option value="1989">1989</option>
                                                <option value="1990">1990</option>
                                                <option value="1991">1991</option>
                                                <option value="1992">1992</option>
                                                <option value="1993">1993</option>
                                                <option value="1994">1994</option>
                                                <option value="1995">1995</option>
                                                <option value="1996">1996</option>
                                                <option value="1997">1997</option>
                                                <option value="1998">1998</option>
                                                <option value="1999">1999</option>
                                                <option value="2000">2000</option>
                                                <option value="2001">2001</option>
                                                <option value="2002">2002</option>
                                                <option value="2003">2003</option>
                                                <option value="2004">2004</option>
                                                <option value="2005">2005</option>
                                                <option value="2006">2006</option>
                                                <option value="2007">2007</option>
                                                <option value="2008">2008</option>
                                                <option value="2009">2009</option>
                                                <option value="2010">2010</option>
                                                <option value="2011">2011</option>
                                                <option value="2012">2012</option>
                                                <option value="2013">2013</option>
                                                <option value="2014">2014</option>
                                                <option value="2015">2015</option>
                                                <option value="2016">2016</option>
                                                <option value="2017">2017</option>
                                                <option value="2018">2018</option>
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <label htmlFor="country">Your country</label>
                                    <select className="custom-select" 
                                    id="country" name="country" 
                                    defaultValue={"default"}
                                    onClick = { (e)=>setCountry(e.target.value) }
                                    
                                    >
                                        <option value="default">Select Country</option> 
                                        <option value="Afghanistan">Afghanistan</option> 
                                        <option value="Albania">Albania</option> 
                                        <option value="Algeria">Algeria</option> 
                                        <option value="American Samoa">American Samoa</option> 
                                        <option value="Andorra">Andorra</option> 
                                        <option value="Angola">Angola</option> 
                                        <option value="Anguilla">Anguilla</option> 
                                        <option value="Antarctica">Antarctica</option> 
                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option> 
                                        <option value="Argentina">Argentina</option> 
                                        <option value="Armenia">Armenia</option> 
                                        <option value="Aruba">Aruba</option> 
                                        <option value="Australia">Australia</option> 
                                        <option value="Austria">Austria</option> 
                                        <option value="Azerbaijan">Azerbaijan</option> 
                                        <option value="Bahamas">Bahamas</option> 
                                        <option value="Bahrain">Bahrain</option> 
                                        <option value="Bangladesh">Bangladesh</option> 
                                        <option value="Barbados">Barbados</option> 
                                        <option value="Belarus">Belarus</option> 
                                        <option value="Belgium">Belgium</option> 
                                        <option value="Belize">Belize</option> 
                                        <option value="Benin">Benin</option> 
                                        <option value="Bermuda">Bermuda</option> 
                                        <option value="Bhutan">Bhutan</option> 
                                        <option value="Bolivia">Bolivia</option> 
                                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option> 
                                        <option value="Botswana">Botswana</option> 
                                        <option value="Bouvet Island">Bouvet Island</option> 
                                        <option value="Brazil">Brazil</option> 
                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option> 
                                        <option value="Brunei Darussalam">Brunei Darussalam</option> 
                                        <option value="Bulgaria">Bulgaria</option> 
                                        <option value="Burkina Faso">Burkina Faso</option> 
                                        <option value="Burundi">Burundi</option> 
                                        <option value="Cambodia">Cambodia</option> 
                                        <option value="Cameroon">Cameroon</option> 
                                        <option value="Canada">Canada</option> 
                                        <option value="Cape Verde">Cape Verde</option> 
                                        <option value="Cayman Islands">Cayman Islands</option> 
                                        <option value="Central African Republic">Central African Republic</option> 
                                        <option value="Chad">Chad</option> 
                                        <option value="Chile">Chile</option> 
                                        <option value="China">China</option> 
                                        <option value="Christmas Island">Christmas Island</option> 
                                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option> 
                                        <option value="Colombia">Colombia</option> 
                                        <option value="Comoros">Comoros</option> 
                                        <option value="Congo">Congo</option> 
                                        <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option> 
                                        <option value="Cook Islands">Cook Islands</option> 
                                        <option value="Costa Rica">Costa Rica</option> 
                                        <option value="Cote D'ivoire">Cote D'ivoire</option> 
                                        <option value="Croatia">Croatia</option> 
                                        <option value="Cuba">Cuba</option> 
                                        <option value="Cyprus">Cyprus</option> 
                                        <option value="Czech Republic">Czech Republic</option> 
                                        <option value="Denmark">Denmark</option> 
                                        <option value="Djibouti">Djibouti</option> 
                                        <option value="Dominica">Dominica</option> 
                                        <option value="Dominican Republic">Dominican Republic</option> 
                                        <option value="Ecuador">Ecuador</option> 
                                        <option value="Egypt">Egypt</option> 
                                        <option value="El Salvador">El Salvador</option> 
                                        <option value="Equatorial Guinea">Equatorial Guinea</option> 
                                        <option value="Eritrea">Eritrea</option> 
                                        <option value="Estonia">Estonia</option> 
                                        <option value="Ethiopia">Ethiopia</option> 
                                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option> 
                                        <option value="Faroe Islands">Faroe Islands</option> 
                                        <option value="Fiji">Fiji</option> 
                                        <option value="Finland">Finland</option> 
                                        <option value="France">France</option> 
                                        <option value="French Guiana">French Guiana</option> 
                                        <option value="French Polynesia">French Polynesia</option> 
                                        <option value="French Southern Territories">French Southern Territories</option> 
                                        <option value="Gabon">Gabon</option> 
                                        <option value="Gambia">Gambia</option> 
                                        <option value="Georgia">Georgia</option> 
                                        <option value="Germany">Germany</option> 
                                        <option value="Ghana">Ghana</option> 
                                        <option value="Gibraltar">Gibraltar</option> 
                                        <option value="Greece">Greece</option> 
                                        <option value="Greenland">Greenland</option> 
                                        <option value="Grenada">Grenada</option> 
                                        <option value="Guadeloupe">Guadeloupe</option> 
                                        <option value="Guam">Guam</option> 
                                        <option value="Guatemala">Guatemala</option> 
                                        <option value="Guinea">Guinea</option> 
                                        <option value="Guinea-bissau">Guinea-bissau</option> 
                                        <option value="Guyana">Guyana</option> 
                                        <option value="Haiti">Haiti</option> 
                                        <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option> 
                                        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option> 
                                        <option value="Honduras">Honduras</option> 
                                        <option value="Hong Kong">Hong Kong</option> 
                                        <option value="Hungary">Hungary</option> 
                                        <option value="Iceland">Iceland</option> 
                                        <option value="India">India</option> 
                                        <option value="Indonesia">Indonesia</option> 
                                        <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option> 
                                        <option value="Iraq">Iraq</option> 
                                        <option value="Ireland">Ireland</option> 
                                        <option value="Israel">Israel</option> 
                                        <option value="Italy">Italy</option> 
                                        <option value="Jamaica">Jamaica</option> 
                                        <option value="Japan">Japan</option> 
                                        <option value="Jordan">Jordan</option> 
                                        <option value="Kazakhstan">Kazakhstan</option> 
                                        <option value="Kenya">Kenya</option> 
                                        <option value="Kiribati">Kiribati</option> 
                                        <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option> 
                                        <option value="Korea, Republic of">Korea, Republic of</option> 
                                        <option value="Kuwait">Kuwait</option> 
                                        <option value="Kyrgyzstan">Kyrgyzstan</option> 
                                        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option> 
                                        <option value="Latvia">Latvia</option> 
                                        <option value="Lebanon">Lebanon</option> 
                                        <option value="Lesotho">Lesotho</option> 
                                        <option value="Liberia">Liberia</option> 
                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option> 
                                        <option value="Liechtenstein">Liechtenstein</option> 
                                        <option value="Lithuania">Lithuania</option> 
                                        <option value="Luxembourg">Luxembourg</option> 
                                        <option value="Macao">Macao</option> 
                                        <option value="North Macedonia">North Macedonia</option> 
                                        <option value="Madagascar">Madagascar</option> 
                                        <option value="Malawi">Malawi</option> 
                                        <option value="Malaysia">Malaysia</option> 
                                        <option value="Maldives">Maldives</option> 
                                        <option value="Mali">Mali</option> 
                                        <option value="Malta">Malta</option> 
                                        <option value="Marshall Islands">Marshall Islands</option> 
                                        <option value="Martinique">Martinique</option> 
                                        <option value="Mauritania">Mauritania</option> 
                                        <option value="Mauritius">Mauritius</option> 
                                        <option value="Mayotte">Mayotte</option> 
                                        <option value="Mexico">Mexico</option> 
                                        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option> 
                                        <option value="Moldova, Republic of">Moldova, Republic of</option> 
                                        <option value="Monaco">Monaco</option> 
                                        <option value="Mongolia">Mongolia</option> 
                                        <option value="Montserrat">Montserrat</option> 
                                        <option value="Morocco">Morocco</option> 
                                        <option value="Mozambique">Mozambique</option> 
                                        <option value="Myanmar">Myanmar</option> 
                                        <option value="Namibia">Namibia</option> 
                                        <option value="Nauru">Nauru</option> 
                                        <option value="Nepal">Nepal</option> 
                                        <option value="Netherlands">Netherlands</option> 
                                        <option value="Netherlands Antilles">Netherlands Antilles</option> 
                                        <option value="New Caledonia">New Caledonia</option> 
                                        <option value="New Zealand">New Zealand</option> 
                                        <option value="Nicaragua">Nicaragua</option> 
                                        <option value="Niger">Niger</option> 
                                        <option value="Nigeria">Nigeria</option> 
                                        <option value="Niue">Niue</option> 
                                        <option value="Norfolk Island">Norfolk Island</option> 
                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option> 
                                        <option value="Norway">Norway</option> 
                                        <option value="Oman">Oman</option> 
                                        <option value="Pakistan">Pakistan</option> 
                                        <option value="Palau">Palau</option> 
                                        <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option> 
                                        <option value="Panama">Panama</option> 
                                        <option value="Papua New Guinea">Papua New Guinea</option> 
                                        <option value="Paraguay">Paraguay</option> 
                                        <option value="Peru">Peru</option> 
                                        <option value="Philippines">Philippines</option> 
                                        <option value="Pitcairn">Pitcairn</option> 
                                        <option value="Poland">Poland</option> 
                                        <option value="Portugal">Portugal</option> 
                                        <option value="Puerto Rico">Puerto Rico</option> 
                                        <option value="Qatar">Qatar</option> 
                                        <option value="Reunion">Reunion</option> 
                                        <option value="Romania">Romania</option> 
                                        <option value="Russian Federation">Russian Federation</option> 
                                        <option value="Rwanda">Rwanda</option> 
                                        <option value="Saint Helena">Saint Helena</option> 
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option> 
                                        <option value="Saint Lucia">Saint Lucia</option> 
                                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option> 
                                        <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option> 
                                        <option value="Samoa">Samoa</option> 
                                        <option value="San Marino">San Marino</option> 
                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option> 
                                        <option value="Saudi Arabia">Saudi Arabia</option> 
                                        <option value="Senegal">Senegal</option> 
                                        <option value="Serbia and Montenegro">Serbia and Montenegro</option> 
                                        <option value="Seychelles">Seychelles</option> 
                                        <option value="Sierra Leone">Sierra Leone</option> 
                                        <option value="Singapore">Singapore</option> 
                                        <option value="Slovakia">Slovakia</option> 
                                        <option value="Slovenia">Slovenia</option> 
                                        <option value="Solomon Islands">Solomon Islands</option> 
                                        <option value="Somalia">Somalia</option> 
                                        <option value="South Africa">South Africa</option> 
                                        <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option> 
                                        <option value="Spain">Spain</option> 
                                        <option value="Sri Lanka">Sri Lanka</option> 
                                        <option value="Sudan">Sudan</option> 
                                        <option value="Suriname">Suriname</option> 
                                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option> 
                                        <option value="Swaziland">Swaziland</option> 
                                        <option value="Sweden">Sweden</option> 
                                        <option value="Switzerland">Switzerland</option> 
                                        <option value="Syrian Arab Republic">Syrian Arab Republic</option> 
                                        <option value="Taiwan, Province of China">Taiwan, Province of China</option> 
                                        <option value="Tajikistan">Tajikistan</option> 
                                        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option> 
                                        <option value="Thailand">Thailand</option> 
                                        <option value="Timor-leste">Timor-leste</option> 
                                        <option value="Togo">Togo</option> 
                                        <option value="Tokelau">Tokelau</option> 
                                        <option value="Tonga">Tonga</option> 
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option> 
                                        <option value="Tunisia">Tunisia</option> 
                                        <option value="Turkey">Turkey</option> 
                                        <option value="Turkmenistan">Turkmenistan</option> 
                                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option> 
                                        <option value="Tuvalu">Tuvalu</option> 
                                        <option value="Uganda">Uganda</option> 
                                        <option value="Ukraine">Ukraine</option> 
                                        <option value="United Arab Emirates">United Arab Emirates</option> 
                                        <option value="United Kingdom">United Kingdom</option> 
                                        <option value="United States">United States</option> 
                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option> 
                                        <option value="Uruguay">Uruguay</option> 
                                        <option value="Uzbekistan">Uzbekistan</option> 
                                        <option value="Vanuatu">Vanuatu</option> 
                                        <option value="Venezuela">Venezuela</option> 
                                        <option value="Viet Nam">Viet Nam</option> 
                                        <option value="Virgin Islands, British">Virgin Islands, British</option> 
                                        <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option> 
                                        <option value="Wallis and Futuna">Wallis and Futuna</option> 
                                        <option value="Western Sahara">Western Sahara</option> 
                                        <option value="Yemen">Yemen</option> 
                                        <option value="Zambia">Zambia</option> 
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                </div>

                                <button className="btn btn-primary btn-block" type="submit">Next</button>
                            </form>
                        </div>

                        <a className="u-login-form py-3 " href="/">
                        Powered by 
                            <img 
                                className="img-fluid pl-2" 
                                src="../img/logo.svg" 
                                width="110" 
                                alt="Stream Dashboard UI Kit" 
                            />
                        </a>
                    </>
                }
                
                
            </div>
            
        </>
    )
}

export default  withRouter(PersonInfo)
import React from 'react'
 import Doughnut from '../Components/Doughnut'
import Layout from '../Components/Layout'
import LinkCard from '../Components/LinkCard'
import axios from 'axios'

import { useState, useEffect } from 'react'

const Dashboard = () => {

  const [all, setAll]         = useState("");
    const [pends, setPends]      = useState("");
    const [apps, setApps]       = useState("");
    const [decline, setDecline] = useState("");
  
  useEffect(()=>{
      const config = {
          headers:{
              "Content-Type":"application/json",
              "x-auth-token":localStorage.getItem("x-auth-token")
          }
      };


      const allUser = async () => {
          try{
              const all = await axios.get("/api/user/total/all", config);
              setAll(all.data.total);
          }catch(err){
              console.log(err);
          }
      };

      const allPends = async () => {
          try{
              const pend = await axios.get("/api/user/total/pend", config);
              setPends(pend.data.total);
          }catch(err){
              console.log(err);
          }
      };

      const allApps = async () =>{
          try {
              const apps = await axios.get("/api/user/total/approved", config);
              setApps(apps.data.total);
          } catch (err) {
              console.log(err);
          }
      };

      const allDecline = async () =>{
          try {
              const dec = await axios.get("/api/user/total/declined", config);
              setDecline(dec.data.total);
          } catch (err) {
              console.log(err);
          }
      };

      allUser()
      allPends()
      allApps()
      allDecline()

  },[]);

  return (
    <div>
        <Layout page={"dashboard"}>
            <Doughnut all={all} pends={pends} apps={apps} decline={decline} />

            <div className="row">
                <LinkCard />
            </div>
        </Layout>
    </div>
  )
}

export default Dashboard

import FusionCard from '../Components/FusionCard'
import Layout from '../Components/Layout'

const Fusion = () => {
    return (
        <div>
             <Layout page={"fusion"}>
                <FusionCard />
            </Layout>
        </div>
    )
}

export default Fusion
import axios from 'axios';
import React from 'react'
import { useEffect, useState } from 'react'
import  {createWorker}  from 'tesseract.js';

const Complete = () => {

    const [loading, setLoading] = useState(true)

    const [vName, setVName] = useState(false)
    const [vExp, setVExp] = useState(false)
    const [vDob, setVDob] = useState(false)
    // const [vFace, setVFace] = useState(false)
    // const [idString, setIdString] = useState("")

    const firstName = sessionStorage.getItem("firstName").toLowerCase()
    const lastName = sessionStorage.getItem("lastName").toLowerCase()
    const exp  = sessionStorage.getItem("exp")
    const dob  = sessionStorage.getItem("dob")
    const imgFront   = sessionStorage.getItem("idFront")
    const selfie   = sessionStorage.getItem("selfie")


    const getBase64 = file => {
        return new Promise(resolve => {
            // let baseURL = "";
            fetch(file)
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(blob => {
                var reader = new FileReader();
                reader.onload = function() {
                    var dataUrl = reader.result;
                    var base64 = dataUrl.split(',')[1];
                    resolve(base64);
                };
                reader.readAsDataURL(blob);
            })
        });
    };


    const verifyImage = async () => {
        const _apiUrl="https://faceapi.mxface.ai/api/v2/Face/verify"
        const _subscriptionKey="qeVkOXHtLhE8ql1EWb-zjRgK6K0xc1128"

        let config = {
            headers: {
                'subscriptionkey': _subscriptionKey
            }
        };

        const body = {
            encoded_image1: await getBase64(imgFront),
            encoded_image2: await getBase64(selfie)
        }


        try{
            const {data} = await axios.post(_apiUrl,body, config)
            console.log(data.matchedFaces[0].confidence)
        }catch(err){
            console.log(err)
        }
        
    }
    verifyImage()


    // const verifyUser = () => {

    //     const config = {
    //         headers:{
    //             "Content-Type":"application/json",
    //             "x-auth-token":localStorage.getItem("x-auth-token")
    //         }
    //     };

    //     const body = {
    //         status: approved
    //     };

    //     try {
            
    //     } catch (err) {
    //         console.log(err.message)
    //     }
    // }
    

    useEffect(()=>{
        const toText = async() => {

            let image, img,  worker;
            image = sessionStorage.getItem("idFront")
            img = image.replace("upload","upload/e_grayscale/e_brightness:40/e_tint:60:black")
            let output = "";


            worker = createWorker();
            await worker.load()
            await worker.loadLanguage("eng")
            await worker.initialize("eng")
 
            const {
              data: { text },
            } = await worker.recognize(img);
            await worker.terminate();
            setLoading(false)

            output = text.replace(/\s/g, "").trim().toLowerCase()

            console.log(output)
            // output +=" 03/25 02/02/1924"

            const name1 = output.includes(lastName)
            const name2 = output.includes(lastName)
            if(name2 && name1){
                console.log("Name is Verified")
                setVName(true)
            }     
            
            const expire = output.includes(exp)
            if(expire){
                setVExp(true)
                console.log("Card Exp Verified")
            }

            const birthday = output.includes(dob)
            if(birthday){
                setVDob(true)
                console.log("Birthday Verified")
            }
        }
        toText()

    },[imgFront, firstName, lastName, exp, dob])

    return (
        <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center bg-white mnh-100vh">
            

            <div className="u-login-form my-auto">
                    <div className="mb-1 text-center">
                        <h1 className="h2">Onboarding processing</h1>
                    </div>
                    {
                        vName &&
                        <div className="mt-1 my-3" style={{ fontSize: "18px"}}>
                            <i className="fas fa-lg fa-check-circle" style={{ color:"green" }}></i> Name verified successfully
                        </div>
                    }
                    {
                        vDob && 
                        <div className="mb-1 my-3" style={{ fontSize: "18px"}}>
                            <i className="fas fa-lg fa-check-circle" style={{ color:"green" }}></i> Date of birth verified successfully
                        </div>
                    }
                    
                    {
                        vExp &&
                        <div className="mb-1 my-3" style={{ fontSize: "18px"}}>
                            <i className="fas fa-lg fa-check-circle" style={{ color:"green" }}></i> Card Expiration verified successfully
                        </div>
                    }

                    
                    
                    <div className="mb-1 my-3" style={{ fontSize: "18px"}}>
                        <i className="fas fa-lg fa-check-circle" style={{ color:"green" }}></i> Face verified successfully
                    </div>
                    {
                        loading &&
                        <div className="mb-1 text-center my-5">
                            <img src="../img/spinner.gif" alt="completed" width={100} />
                        </div>
                    }
                    
                    
                    
                    <button 
                        className="btn btn-primary btn-block mt-2" 
                        type="submit" 
                    
                        >OK</button>
            </div>
            <div className="u-login-form text-muted py-3 mt-auto">
                <small><i className="far fa-question-circle mr-1"></i> If you are having troubles with your KYC process <a href="/contact">contact us</a>.</small>
            </div>
            <a className="u-login-form py-3 mb-auto" href="/">
                powered by
                <img className="img-fluid" src="../img/logo.svg" width="160" alt="Soft KYC Logo" />
            </a>
        </div>
    )
}

export default Complete
import React from 'react'
import DeclineCard from '../Components/DeclineCard'
import Layout from '../Components/Layout'

const Decline = () => {
    return (
        <div>
            <Layout page={"decline"}>
                <DeclineCard />
            </Layout>
        </div>
    )
}

export default Decline
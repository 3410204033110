import React, { useState } from 'react'
import axios from "axios";

const RegisterForm = () => {
    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [pass, setPass]   = useState("");
    const [conPass, setConPass] = useState("");

    const [error, setError] = useState("");

   const handleSubmit = async (e) => {
        e.preventDefault();

        if(name===""){
            setError("Name field is empty");
        }else if(email===""){
            setError("Email field is empty");
        }else if(pass===""){
            setError("Password field is empty");
        }else if(conPass===""){
            setError("Confirm password field is empty");
        }else if(pass!==conPass){
            setError("Passwords do not match");
        }else{

            const config = {
                headers:{
                    "Content-Type":"application/json",
                }
            }

            const body = {
                name:name, email: email, password: pass
            }

            try {
                const res = await axios.post("/api/client", body, config);
              
                if(res.status!==200){
                    setError(res.data.msg);
                }else{
                    localStorage.setItem("x-auth-token", res.data.token);
                    window.location.href="/business";
                }

            } catch (err) {
                console.log(err);
            }
        }
        
   }
    
    return (
        <div 
            className="col-lg-6 d-flex flex-column 
                justify-content-center 
                align-items-center bg-white mnh-100vh">
            <a className="u-login-form py-3 mb-auto" href="index.html">
                <img className="img-fluid" src="./img/logo.svg" width="160" alt="Soft KYC Logo" />
            </a>

            <div className="u-login-form">
                <form className="mb-3" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <h1 className="h2">Claim Your Free account!</h1>
                        <p className="small">Login to your dashboard with your registered email address and password.</p>
                    </div>
                    { error && <div className="alert alert-danger">{error}</div>}
                    <div className="form-group mb-4">
                        <label htmlFor="name">Your name</label>
                        <input id="name" 
                            className="form-control" 
                            name="name" type="text" 
                            placeholder="John Doe" 
                            value={ name }
                            onChange={
                                (e)=>setName(e.target.value)
                            }
                        />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="email">Your email</label>
                        <input 
                            id="email" className="form-control" 
                            name="email" type="email" 
                            placeholder="example@domain.com"
                            value={ email } 
                            onChange = {
                                (e)=>setEmail(e.target.value)
                            }
                        />
                    </div>

                    <div className="form-row">
                        <div className="col-md-6">
                            <div className="form-group mb-4">
                                <label htmlFor="password">Password</label>
                                <input 
                                    id="password" className="form-control" 
                                    name="password" type="password" 
                                    placeholder="Enter your password" 
                                    value={ pass } 
                                    onChange = {
                                        (e)=>setPass(e.target.value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group mb-4">
                                <label htmlFor="confirmPassword">Confirm password</label>
                                <input 
                                    id="confirmPassword" 
                                    className="form-control" 
                                    name="confirmPassword" 
                                    type="password" 
                                    value={conPass} 
                                    onChange= {
                                        (e)=>setConPass(e.target.value)
                                    }
                                    placeholder="Re-enter your password" 
                                />
                            </div>
                        </div>
                    </div>

                    <div className="custom-control custom-checkbox mb-4">
                        <input 
                            id="rememberMe" className="custom-control-input" 
                            name="rememberMe" type="checkbox"
                            value={ conPass}

                        />
                        <label 
                            className="d-block custom-control-label" 
                            htmlFor="rememberMe">
                                I agree with 
                            <a href="/terms">terms &amp; conditions</a>
                        </label>
                    </div>

                    <button 
                        className="btn btn-primary btn-block" 
                        type="submit">Sign Up
                    </button>
                </form>

                <p className="small">
                    Already have an account? <a href="/login">Login here</a>
                </p>
            </div>

            <div className="u-login-form text-muted py-3 mt-auto">
                <small><i className="far fa-question-circle mr-1"></i> 
                If you are not able to sign up, please 
                <a href="/contact">contact us</a>.</small>
            </div>
        </div>
    )
}

export default RegisterForm
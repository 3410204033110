

const Doughnut = ({all, pends, apps, decline}) => {
    

    
    
    return (
        <div className="row">
            <div className="col-sm-6 col-xl-3 mb-4">
                <div className="card">
                    <div className="card-body media align-items-center px-xl-3">
                        
                        <div className="u-doughnut u-doughnut--70 mr-3 mr-xl-2">
                            <div className="u-doughnut__label text-success">
                                <i className="fas fa-chalkboard-teacher"></i>
                            </div>
                        </div>

                        <div className="media-body">
                            <h5 className="h6 text-muted text-uppercase mb-2">
                                Total <i className="fa fa-arrow-up text-success ml-1"></i>
                            </h5>
                            <span className="h2 mb-0">{new Intl.NumberFormat().format(all)}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-xl-3 mb-4">
                <div className="card">
                    <div className="card-body media align-items-center px-xl-3">
                        <div className="u-doughnut u-doughnut--70 mr-3 mr-xl-2">
                            <div className="u-doughnut__label text-warning">
                                <i className="fas fa-chalkboard-teacher"></i>
                            </div>
                        </div>

                        <div className="media-body">
                            <h5 className="h6 text-muted text-uppercase mb-2">
                                Pending <i className="fa fa-arrow-up text-success ml-1"></i>
                            </h5>
                            <span className="h2 mb-0">{ new Intl.NumberFormat().format(pends) }</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-xl-3 mb-4">
                <div className="card">
                    <div className="card-body media align-items-center px-xl-3">
                        <div className="u-doughnut u-doughnut--70 mr-3 mr-xl-2">
                            <div className="u-doughnut__label text-primary">
                                <i className="fas fa-chalkboard-teacher"></i>
                            </div>
                        </div>

                        <div className="media-body">
                            <h5 className="h6 text-muted text-uppercase mb-2">
                                Approved <i className="fa fa-arrow-up text-primary ml-1"></i>
                            </h5>
                            <span className="h2 mb-0"> { new Intl.NumberFormat().format(apps) } </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-xl-3 mb-4">
                <div className="card">
                    <div className="card-body media align-items-center px-xl-3">
                        <div className="u-doughnut u-doughnut--70 mr-3 mr-xl-2">
                            <div className="u-doughnut__label text-danger">
                                <i className="fas fa-chalkboard-teacher"></i>
                            </div>
                        </div>

                        <div className="media-body">
                            <h5 className="h6 text-muted text-uppercase mb-2">
                                Declined <i className="fa fa-arrow-up text-danger ml-1"></i>
                            </h5>
                            <span className="h2 mb-0">{  new Intl.NumberFormat().format(decline)  }</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Doughnut
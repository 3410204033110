import React, { useState } from 'react'
import useStore from '../store/useStore';

const LinkCard = () => {
    const user = useStore((state)=>state.user)
    const [copied, setIsCopied] = useState(false)

    let newsUrl = window.location.href,
    newsSplit = newsUrl.split('/'),
    userUrl = newsSplit[newsSplit.length-2];

    const handleCopy = () =>{
        var copyText = document.getElementById("link");

        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
        setIsCopied(true);

        setTimeout(()=>setIsCopied(false), 3000);
    }


    return (
        <div className="col-lg-6">
            <div className="card mb-4">
                <header className="card-header">
                    <div className="row justify-content-between">
                        <h2 className="h3 card-header-title ml-1">Your business KYC Link</h2>
                    </div>
                </header>

                <div className="card-body">
                    { user && 
                        <>
                            <div className="input-group mb-3">
                                <p>Please copy the link below and send to uses to take their KYC Onboarding</p>
                                <input 
                                    id="link"
                                    type="text" 
                                    className="form-control" 
                                    value={"https://"+userUrl+"/step0/"+user._id}
                                    disabled style={{backgroundColor: "#fff"}} aria-label="kyc link" aria-describedby="kyc-link" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={handleCopy} >Copy Link</button>
                                </div>
                            </div>
                        </>                    
                    }
                    
                    { (copied) && <div className='alert alert-success'>KYC Link Copied</div>}
                </div>
            </div>
        </div>
    )
}

export default LinkCard

const Sidebar = ({page}) => {
    return (
        <aside id="sidebar" className="u-sidebar">
            <div className="u-sidebar-inner">
                <header className="u-sidebar-header">
                    <a className="u-sidebar-logo" href="/dashboard">
                        <img className="img-fluid" src="./img/logo.svg" width="124" alt="Soft KYC" />
                    </a>
                </header>

                <nav className="u-sidebar-nav">
                    <ul className="u-sidebar-nav-menu u-sidebar-nav-menu--top-level">
                        <li className="u-sidebar-nav-menu__item">
                            <a className={(page==="dashboard")? "u-sidebar-nav-menu__link active":"u-sidebar-nav-menu__link"} href="/admin_dash">
                                <i className="fa fa-home u-sidebar-nav-menu__item-icon"></i>
                                <span className="u-sidebar-nav-menu__item-title">Dashboard</span>
                            </a>
                        </li>

                        <li className="u-sidebar-nav-menu__item">
                            <a className={ (page==="business")? "u-sidebar-nav-menu__link active":"u-sidebar-nav-menu__link" } href="/admin_buss">
                                <i className="far fa-calendar-alt u-sidebar-nav-menu__item-icon"></i>
                                <span className="u-sidebar-nav-menu__item-title">Businesses</span>
                            </a>
                        </li>              

                        <hr/>

                        <li className="u-sidebar-nav-menu__item">
                            <a className={ (page==="password")? "u-sidebar-nav-menu__link active":"u-sidebar-nav-menu__link" } href="/admin_password">
                                <i className="fas fa-lock u-sidebar-nav-menu__item-icon"></i>
                                <span className="u-sidebar-nav-menu__item-title">Change Password</span>
                            </a>
                        </li>


                        <li className="u-sidebar-nav-menu__item">
                            <a className="u-sidebar-nav-menu__link" href="/">
                                <i className="far fa-share-square u-sidebar-nav-menu__item-icon"></i>
                                <span className="u-sidebar-nav-menu__item-title">Sign out</span>
                            </a>
                        </li>

                    </ul>
                </nav>
            </div>
        </aside>
    )
}

export default Sidebar
import { useEffect, useState } from "react";
import axios from "axios";

import useStore from "../store/useStore";

const FusionCard = () => {
    const [users, setUsers] = useState(null);

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");

    const [email, setEmail] = useState("");
    const [dob, setDob] = useState("");
    const [country, setCountry] = useState("");
    const [selfie, setSelfie] = useState("");

    const [cardType, setCardType] = useState("");
    const [cardFront, setCardFront] = useState("");
    const [cardBack, setCardBack] = useState("");

    const [loading, setLoading] = useState(false);

    const all = useStore((state) => state.all);
    const getAll = useStore((state) => state.getAll);

    const approve = useStore((state) => state.approved);
    const getApproved = useStore((state) => state.getApproved);

    const decline = useStore((state) => state.decline);
    const getDeclined = useStore((state) => state.getDeclined);
    // const [approve, setApprove] = useState(0)
    // const [decline, setDecline] = useState(0)
    // const [total, setTotal]     = useState(0)

    //View user details
    const viewUser = async (id) => {
        try {
            try {
                const oneUser = users.filter((user) => user.id === id);

                setFirstName(oneUser[0].firstname);
                setMiddleName(oneUser[0].middlename);
                setLastName(oneUser[0].lastname);
                setEmail(oneUser[0].email);
                setDob(oneUser[0].dob);

                setCountry(oneUser[0].country);
                setSelfie(oneUser[0].selfie);

                setCardType(oneUser[0].cardType);
                setCardFront(oneUser[0].document_front);
                setCardBack(oneUser[0].document_back);
            } catch (err) {
                console.log(err);
            }
        } catch (err) {
            console.log("Error Check");
        }
    };

    //Verify User
    const verifyUser = async (id) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const body = {
            uuid: id,
            status: "approved",
        };

        try {
            const { data } = await axios.post(
                "https://api.fusionprotocol.io/api/kyc/updateKyc",
                body,
                config
            );
            const ver = data.data;

            if (ver) {
                const newUser = users.filter((user) => {
                    return user.uuid !== id;
                });

                setUsers(newUser);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const declineUser = async (id) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const body = {
            uuid: id,
            status: "rejected",
        };

        try {
            const { data } = await axios.post(
                "https://api.fusionprotocol.io/api/kyc/updateKyc",
                body,
                config
            );
            const ver = data.data;

            if (ver) {
                const newUser = users.filter((user) => {
                    return user.uuid !== id;
                });

                setUsers(newUser);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const exportApproved = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get("/api/user/fusion/all");

            // Convert the data objects into rows of CSV text

            // const allData = JSON.parse(data.data)
            // console.log(allData)

            const rows = data.map((d) => {
                return Object.values(d).join(",");
            });

            // Prepend the column names as the first row
            const columnNames = Object.keys(data[0]);
            rows.unshift(columnNames.join(","));

            // Join the rows into a single CSV string
            const csv = rows.join("\n");

            // Create a hidden link element and click it to trigger the download
            const link = document.createElement("a");
            link.style.display = "none";
            link.setAttribute(
                "href",
                "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
            );
            link.setAttribute("download", "data.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const getPending = async () => {
            try {
                const { data } = await axios.get(
                    "https://api.fusionprotocol.io/api/kyc/fetchall"
                );
                if (data.data) {
                    let allData = data.data;
                    let records = allData.filter(
                        (data) => data.status === "pending"
                    );
                    // console.log(record);
                    console.log("Pending: " + records.length);
                    setUsers(records);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getPending();

        getAll();
        getApproved();
        getDeclined();
    }, [getAll, getApproved, getDeclined, users]);

    return (
        <div className="row">
            <div className="col-sm-6">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-3">
                            <div className="card-body media align-items-center px-xl-3">
                                <div className="media-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <h5 className="h6 text-muted text-uppercase mb-2">
                                                Approved{" "}
                                                <i className="fa fa-arrow-up text-success ml-1"></i>
                                            </h5>
                                            <span className="h4 mb-0">
                                                {new Intl.NumberFormat().format(
                                                    approve
                                                )}
                                            </span>
                                        </div>
                                        <div className="col-md-4">
                                            <h5 className="h6 text-muted text-uppercase mb-2">
                                                Declined{" "}
                                                <i className="fa fa-arrow-up text-success ml-1"></i>
                                            </h5>
                                            <span className="h4 mb-0">
                                                {new Intl.NumberFormat().format(
                                                    decline
                                                )}
                                            </span>
                                        </div>
                                        <div className="col-md-4">
                                            <h5 className="h6 text-muted text-uppercase mb-2">
                                                Total{" "}
                                                <i className="fa fa-arrow-up text-success ml-1"></i>
                                            </h5>
                                            <span className="h4 mb-0">
                                                {new Intl.NumberFormat().format(
                                                    all
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-4">
                        <div className="card">
                            <div className="card-body media align-items-center px-xl-3">
                                <div className="media-body">
                                    <h5 className="h6 text-muted text-uppercase mb-2">
                                        Approved <i className="fa fa-arrow-up text-success ml-1"></i>
                                    </h5>
                                    <span className="h4 mb-0">{new Intl.NumberFormat().format(2334343)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body media align-items-center px-xl-3">
                                <div className="media-body">
                                    <h5 className="h6 text-muted text-uppercase mb-2">
                                        Decline <i className="fa fa-arrow-up text-success ml-1"></i>
                                    </h5>
                                    <span className="h4 mb-0">{new Intl.NumberFormat().format(2334343)}</span>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {users &&
                        users.map((user) => (
                            <div className="col-md-6 mb-4" key={user.id}>
                                <div className="card">
                                    <div className="card-body p-3">
                                        <h5 className="h3 card-title">
                                            {user.firstname +
                                                " " +
                                                user.middlename +
                                                " " +
                                                user.lastname}
                                        </h5>
                                        <h6 className="card-subtitle text-muted mb-3">
                                            {user.email}
                                        </h6>

                                        <button
                                            className="btn btn-sm btn-success mr-1"
                                            onClick={() =>
                                                verifyUser(user.uuid)
                                            }
                                        >
                                            <i className="fas fa-check"></i>
                                        </button>
                                        <button
                                            className="btn btn-sm btn-danger mr-1"
                                            onClick={() =>
                                                declineUser(user.uuid)
                                            }
                                        >
                                            <i className="fas fa-ban"></i>
                                        </button>
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => viewUser(user.id)}
                                        >
                                            <i className="fas fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                {/* <div className="d-flex justify-content-between">
                    <button 
                        className="btn btn-secondary m-1" 
                        onClick={()=>prev()}>Prev
                    </button>

                    <button 
                        className="btn btn-secondary m-1" 
                        onClick={()=>next()}>More
                    </button>
                </div> */}
            </div>
            <div className="col-sm-6 float">
                <div className="card">
                    <div className="card-header d-flex">
                        <h6 className="h3 text-title">User Details</h6>
                        <ul className="list-inline ml-auto mb-0">
                            <li className="list-inline-item mr-3">
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => exportApproved()}
                                    disabled={loading ? true : false}
                                >
                                    {loading ? "Exporting KYC" : "Export KYC"}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-7">
                                {firstName && lastName && (
                                    <>
                                        <span className="small text-uppercase">
                                            Name
                                        </span>
                                        <div style={{ fontWeight: 600 }}>
                                            {firstName +
                                                " " +
                                                middleName +
                                                " " +
                                                lastName}
                                        </div>
                                    </>
                                )}
                                {email && (
                                    <>
                                        <span className="small text-uppercase">
                                            Email
                                        </span>
                                        <div style={{ fontWeight: 600 }}>
                                            {email}
                                        </div>
                                    </>
                                )}
                                {dob && (
                                    <>
                                        <span className="small text-uppercase">
                                            Birthday
                                        </span>
                                        <div style={{ fontWeight: 600 }}>
                                            {dob}
                                        </div>
                                    </>
                                )}
                                {country && (
                                    <>
                                        <span className="small text-uppercase">
                                            Country
                                        </span>
                                        <div style={{ fontWeight: 600 }}>
                                            {country}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="col-md-5">
                                {selfie && (
                                    <img
                                        src={selfie}
                                        alt="passport"
                                        width={150}
                                        height={150}
                                    />
                                )}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                {cardType && (
                                    <>
                                        <span className="small text-uppercase">
                                            Card Type
                                        </span>
                                        <div style={{ fontWeight: 600 }}>
                                            {cardType}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                {cardBack && (
                                    <img
                                        src={cardBack}
                                        alt="Card Front"
                                        className="img-fluid"
                                    />
                                )}
                                <a
                                    href={cardBack}
                                    target="_blanck"
                                    className="btn btn-block btn-primary"
                                >
                                    View Photo
                                </a>
                            </div>
                            <div className="col-md-6">
                                {cardFront && (
                                    <img
                                        src={cardFront}
                                        alt="Card Front"
                                        className="img-fluid"
                                    />
                                )}
                                <a
                                    href={cardFront}
                                    target="_blanck"
                                    className="btn btn-block btn-primary"
                                >
                                    View Photo
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FusionCard;

import Layout from '../Components/Layout'
import UserCard from '../Components/UserCard'

const Pending = () => {

  return (
    <div>
        <Layout page="pending">
            <UserCard />
        </Layout>
    </div>
  )
}

export default Pending
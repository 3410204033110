import React from 'react'

const BoardLayout = ({ children }) => {
    return (
        <main className="container-fluid w-100" role="main">
            <div className="row">
                {children}

                <div className="col-lg-6 d-none d-lg-flex flex-column align-items-center justify-content-center bg-light">
					<img 
                        className="img-fluid position-relative u-z-index-3 mx-5" 
                        src="../img/side.png" 
                        alt="corporate user"
                    />					
				</div>
            </div>
        </main>
    )
}

export default BoardLayout
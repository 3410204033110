import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";

const store = (set) =>({
    user:[],

    setUser: async () => {
        const config = {
            headers:{
                "Content-Type":"application/json",
                "x-auth-token":localStorage.getItem("x-auth-token")
            }
        }
        try{
            const user  =  await axios.get("/api/client", config);
            if(!user){
                localStorage.removeItem("x-auth-token")
            }
            set({ user: user.data });

        }catch(err){
            localStorage.removeItem("x-auth-token")
            console.log(err.response.data)
        }
    },

    kycuser: [],
    
    getAllUser: async () => {
        const config = {
            headers:{
                "Content-Type":"application/json",
                "x-auth-token":localStorage.getItem("x-auth-token")
            }
        };

        try{
            const users = await axios.get("/api/user/total/all", config);
            set({ kycuser: users.data });
        }catch(err){
            console.log(err.response.data);
        }
    },
    
    all: 0,
    getAll : async () =>{
        try {
            const {data} = await axios.get("/api/user/fusion/total")
            set({ all: data.total });
        } catch (err) {
            console.log(err)
        }
    },

    approved: 0,
    getApproved : async () =>{
        try {
            const {data} = await axios.get("/api/user/fusion/approved/total")
            set({ approved: data.total });
        } catch (err) {
            console.log(err)
        }
    },

    decline:0,
    getDeclined : async () => {
        try {
            const {data} = await axios.get("/api/user/fusion/rejected/total")
            set({ decline: data.total });
        } catch (err) {
            console.log(err)
        }
    }
    
})

const useStore = create(
    devtools(
        store
    )
)

export default useStore;
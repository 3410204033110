import React from 'react'
import ChangePassword from '../Admin/ChangePassword'
import AdminLayout from '../Components/AdminLayout'

const AdminPassword = () => {
    return (
        <AdminLayout page="password">
            <div className="row">
                <ChangePassword />
            </div>
        </AdminLayout>
    )
}

export default AdminPassword
import React from 'react'
import AuthLayout from '../Components/AuthLayout'
import AuthLeft from '../Components/AuthLeft'
import LoginForm from '../Components/LoginForm'

const Login = () => {
	return (
		<div>
			<AuthLayout>
				<LoginForm />
				<AuthLeft />
			</AuthLayout>
		</div>
	)
}

export default Login
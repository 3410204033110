import { useEffect } from "react"
import axios from "axios"
const Verify = ({match}) => {

    const {email, user} = match.params
    
    


    useEffect(()=>{
        const verifyPay = async () => {
            const config = {
                headers:{
                    "Content-Type":"application/json"
                }
            }

            const body = {
                email: email, 
                user: user
            }

            try {
                const {status} = await axios.post("/api/verify", body, config);
                if(status===200){
                    window.location.href="/profile"
                }

            } catch (err) {
                console.log(err)
            }
        }

        verifyPay()

        // setTimeout(()=>{
        //     window.location.href="/dashboard"
        // }, 5000)
        
    },[email, user])
    return (
        <div>Verify</div>
    )
}

export default Verify

import React from 'react'
import AuthLayout from '../Components/AuthLayout'
import AuthLeft from '../Components/AuthLeft'
import BusinessForm from '../Components/BusinessForm'

const RegisterBusiness = () => {
    return (
        <div>
            <AuthLayout>
                <BusinessForm />
                <AuthLeft />
            </AuthLayout>
        </div>
    )
}

export default RegisterBusiness
import React from 'react'

const ProTabHeader = () => {
    return (
        <div className="card-header d-md-flex align-items-center">
            <h2 className="h3 card-header-title">Profile</h2>

            <ul className="nav nav-tabs card-header-tabs ml-md-auto mt-3 mt-md-0">
                <li className="nav-item">
                    <a 
                        href="#panelWithTabsTab2" 
                        className="nav-link active show" 
                        role="tab" 
                        aria-selected="true" 
                        data-toggle="tab">Account Profile
                    </a>
                </li>
                <li className="nav-item">
                    <a href="#panelWithTabsTab1" 
                        className="nav-link" 
                        role="tab" 
                        aria-selected="false" 
                        data-toggle="tab">Billing
                    </a>
                </li>
                
            </ul>
        </div>
    )
}

export default ProTabHeader
import React from 'react'
import ProAcctTab  from './ProAcctTab'
import ProBussTab from './ProBussTab'
import ProImageCard from './ProImageCard'
import ProTabHeader from './ProTabHeader'


const ProfileCard = ( ) => {


    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className="row">
                    <ProImageCard />

                    <div className="col-md-8">
                        

                        <div className="card-body">
                            <div className="tab-content">
                                <ProTabHeader />                               

                                <ProBussTab />

                                <ProAcctTab />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileCard
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import { Fragment } from 'react';

import Landing from "./Pages/Landing";
import Company from "./Pages/Company";
import Dashboard from './Pages/Dashboard';
import Pending from './Pages/Pending';

import Register from "./Pages/Register";
import Login from "./Pages/Login";
import ForgetPassword from "./Pages/ForgetPassword";

import Decline from "./Pages/Decline";
import Approve from "./Pages/Approve";
import Support from "./Pages/Support";
import Export from "./Pages/Export";

import OnboardOne from "./Onboard/OnboardOne";
import OnboardTwo from "./Onboard/OnboardTwo";
import OnboardThree from "./Onboard/OnboardThree";
import OnboardFour from "./Onboard/OnboardFour";

import RouteGuard from "./Pages/RouteGuard";
import OnboardComplete from "./Onboard/OnboardComplete";
import Profile from "./Pages/Profile";
import Verify from "./Pages/Verify";
import RegisterBusiness from "./Pages/RegisterBusiness";

import AdminDash from "./Pages/AdminDash";
import AdminBuss from "./Pages/AdminBuss";
import AdminFussion from "./Pages/AdminFussion";
import Fusion from "./Pages/Fusion";
import AdminPassword from "./Pages/AdminPassword";
import Password from "./Pages/Password";
import OnboardZero from "./Onboard/OnboardZero";

function App() {

    return (
      <Router>
        <Fragment>
            
            <section>
                <Switch>
                    <Route exact path="/" component={Landing} />
                    <Route exact path="/company" component={Company} />
                    <Route exact path="/developer" component={Landing} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/business" component={RegisterBusiness} />
                    <Route exact path="/login" component={Login} />

                    <Route exact path="/forget" component={ForgetPassword} />

                    

                    <Route exact path="/step0/*" component={OnboardZero} />
                    <Route exact path="/step1/*" component={OnboardOne} />
                    <Route exact path="/step2/*" component={OnboardTwo} />
                    <Route exact path="/step3/*" component={OnboardThree} />
                    <Route exact path="/step4/*" component={OnboardFour} />
                    <Route exact path="/step/completed" component={OnboardComplete} />
                    <Route exact path="/verify/:email/:user" component={Verify} />
                    
                    <RouteGuard>
                      <Route exact path="/dashboard" component={Dashboard} />
                      <Route exact path="/pending" component={Pending} />
                      <Route exact path="/decline" component={Decline} />
                      <Route exact path="/approve" component={Approve} />
                      <Route exact path="/support" component={Support} />
                      <Route exact path="/profile" component={Profile} />
                      <Route exact path="/export" component={Export} />

                      <Route exact path="/fusion" component={Fusion} />
                      <Route exact path="/password" component={Password} />

                      <Route exact path="/admin_dash" component={AdminDash} />
                      <Route exact path="/admin_buss" component={AdminBuss} />
                      <Route exact path="/admin_fussion" component={AdminFussion} />
                      <Route exact path="/admin_password" component={AdminPassword} />
                      
                    </RouteGuard>                    
                </Switch>
            </section>
        </Fragment>
      </Router>
    );
}

export default App;

import React from 'react'
import BoardLayout from '../Components/BoardLayout'
import SelfieInfo from "../Components/SelfieInfo"

const OnboardFour = () => {
  return (
    <div>
        <BoardLayout>
          <SelfieInfo />
        </BoardLayout>
    </div>
  )
}

export default OnboardFour
import React from "react";
import { useState } from "react";

import axios from "axios";

const LoginForm = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const loginUser = async () => {
        const config = {
            header: {
                "Content-Type": "application/json",
            },
        };

        const body = {
            email: email,
            password: password,
        };

        try {
            setLoading(true);
            const { data } = await axios.post("/api/auth", body, config);
            localStorage.setItem("x-auth-token", data.token);

            setLoading(false);

            if (data.role === "client") {
                if (email === "digitechsols1@gmail.com") {
                    localStorage.setItem("account", "fusion");
                }
                window.location.href = "/dashboard";
            } else if (data.role === "admin") {
                window.location.href = "/admin_dash";
            }
        } catch (err) {
            setError("Invalid Credentials");
            setPassword("");
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (email === "" || password === "") {
            setError("Enter login Details");
        } else {
            loginUser();
        }
    };

    return (
        <div
            className="col-lg-6 d-flex flex-column 
                justify-content-center align-items-center 
                bg-white mnh-100vh"
        >
            <a className="u-login-form py-3 mb-auto" href="/login">
                <img
                    className="img-fluid"
                    src="./img/logo.svg"
                    width="160"
                    alt="Soft KYC"
                />
            </a>
            {loading && <div>Loading...</div>}
            <div className="u-login-form">
                <form className="mb-3" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <h1 className="h2">Welcome Back!</h1>
                        <p className="small">
                            Login to your dashboard with your registered email
                            address and password.
                        </p>
                    </div>

                    {error.length > 0 && (
                        <div className="alert alert-danger">{error}</div>
                    )}

                    <div className="form-group mb-4">
                        <label htmlFor="email">Your email</label>
                        <input
                            id="email"
                            className="form-control"
                            name="email"
                            type="email"
                            placeholder="example@domain.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="password">Password</label>
                        <input
                            id="password"
                            className="form-control"
                            name="password"
                            type="password"
                            placeholder="Your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="form-group d-flex justify-content-between align-items-center mb-4">
                        <div className="custom-control custom-checkbox">
                            <input
                                id="rememberMe"
                                className="custom-control-input"
                                name="rememberMe"
                                type="checkbox"
                            />
                            <label
                                className="custom-control-label"
                                htmlFor="rememberMe"
                            >
                                Remember me
                            </label>
                        </div>

                        <a className="link-muted small" href="/forget">
                            Forgot Password?
                        </a>
                    </div>

                    <button className="btn btn-primary btn-block" type="submit">
                        Login
                    </button>
                </form>

                <p className="small">
                    Don't have an account? <a href="/register">Sign Up here</a>
                </p>
            </div>

            <div className="u-login-form text-muted py-3 mt-auto">
                <small>
                    <i className="far fa-question-circle mr-1"></i>
                    If you are not able to sign in, please
                    <a href="/contact">contact us</a>.
                </small>
            </div>
        </div>
    );
};

export default LoginForm;

import { withRouter } from "react-router-dom";
const WelcomeKyc = ({history}) => {

    var newsUrl = window.location.href,
    newsSplit = newsUrl.split('/'),
    clientId = newsSplit[newsSplit.length-1];

    const toNext = () => {
        history.push("/step1/"+clientId);
    }

    return(
        <div className="col-lg-6 d-flex flex-column 
                justify-content-center align-items-center 
                bg-white mnh-100vh pt-5">
            <h2>Welcome, Start your KYC </h2>
            
            <img src="../img/kyc_welcome.png" alt="Welcome Vector" />

            <div className="card m-3 p-3">
                <ul>
                    <li>Upload photos of documents proving your identity</li>
                    <li>Take a selfie for photo verification</li>
                    <li>Please allow access to the camera</li>
                </ul>
                <button 
                    className="btn btn-lg btn-block btn-primary mt-3" 
                    onClick={toNext}>Start KYC
                </button>
            </div>

           
        </div>
    )
}

export default withRouter(WelcomeKyc)
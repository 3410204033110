import { useEffect, useState } from "react";

const Sidebar = ({ page }) => {
    const [acct, setAcct] = useState("");

    useEffect(() => {
        const acct = localStorage.getItem("account");
        if (acct === "fusion") {
            setAcct(acct);
        }
    }, [setAcct]);

    const signout = () => {
        localStorage.removeItem("x-auth-token");
    };

    return (
        <aside id="sidebar" className="u-sidebar ">
            <div className="u-sidebar-inner ">
                <header className="u-sidebar-header">
                    <a className="u-sidebar-logo" href="/dashboard">
                        <img
                            className="img-fluid"
                            src="./img/logo.svg"
                            width="124"
                            alt="Soft KYC"
                        />
                    </a>
                </header>

                <nav className="u-sidebar-nav">
                    <ul className="u-sidebar-nav-menu u-sidebar-nav-menu--top-level">
                        {acct && (
                            <>
                                <li className="u-sidebar-nav-menu__item">
                                    <a
                                        className={
                                            page === "fusion"
                                                ? "u-sidebar-nav-menu__link active"
                                                : "u-sidebar-nav-menu__link"
                                        }
                                        href="/fusion"
                                    >
                                        <i className="fa fa-link u-sidebar-nav-menu__item-icon"></i>
                                        <span className="u-sidebar-nav-menu__item-title">
                                            integrated KYC
                                        </span>
                                    </a>
                                </li>
                            </>
                        )}
                        <li className="u-sidebar-nav-menu__item">
                            <a
                                className={
                                    page === "dashboard"
                                        ? "u-sidebar-nav-menu__link active"
                                        : "u-sidebar-nav-menu__link"
                                }
                                href="/dashboard"
                            >
                                <i className="fa fa-home u-sidebar-nav-menu__item-icon"></i>
                                <span className="u-sidebar-nav-menu__item-title">
                                    Single Link KYC
                                </span>
                            </a>
                        </li>

                        <li className="u-sidebar-nav-menu__item">
                            <a
                                className={
                                    page === "pending"
                                        ? "u-sidebar-nav-menu__link active"
                                        : "u-sidebar-nav-menu__link"
                                }
                                href="/pending"
                            >
                                <i className="far fa-calendar-alt u-sidebar-nav-menu__item-icon"></i>
                                <span className="u-sidebar-nav-menu__item-title">
                                    Pending
                                </span>
                            </a>
                        </li>

                        <li className="u-sidebar-nav-menu__item">
                            <a
                                className={
                                    page === "approved"
                                        ? "u-sidebar-nav-menu__link active"
                                        : "u-sidebar-nav-menu__link"
                                }
                                href="/approve"
                            >
                                <i className="far fa-id-card u-sidebar-nav-menu__item-icon"></i>
                                <span className="u-sidebar-nav-menu__item-title">
                                    Approved
                                </span>
                            </a>
                        </li>

                        <li className="u-sidebar-nav-menu__item">
                            <a
                                className={
                                    page === "decline"
                                        ? "u-sidebar-nav-menu__link active"
                                        : "u-sidebar-nav-menu__link"
                                }
                                href="/decline"
                            >
                                <i className="fas fa-money-check-alt u-sidebar-nav-menu__item-icon"></i>
                                <span className="u-sidebar-nav-menu__item-title">
                                    Decline
                                </span>
                            </a>
                        </li>

                        <hr />

                        <li className="u-sidebar-nav-menu__item">
                            <a
                                className={
                                    page === "export"
                                        ? "u-sidebar-nav-menu__link active"
                                        : "u-sidebar-nav-menu__link"
                                }
                                href="/export"
                            >
                                <i className="fas fa-file-export u-sidebar-nav-menu__item-icon"></i>
                                <span className="u-sidebar-nav-menu__item-title">
                                    Export
                                </span>
                            </a>
                        </li>

                        <li className="u-sidebar-nav-menu__item">
                            <span
                                className="u-sidebar-nav-menu__link"
                                onClick={signout}
                            >
                                <i className="far fa-share-square u-sidebar-nav-menu__item-icon"></i>
                                <span className="u-sidebar-nav-menu__item-title">
                                    Sign out
                                </span>
                            </span>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default Sidebar;

import React from 'react'

const Footer = () => {
    return (
        <footer className="u-footer d-md-flex align-items-md-center text-center text-md-left text-muted text-muted">
            <p className="h5 mb-0 ml-auto">
                &copy; {new Date().getFullYear()}. All Rights Reserved.
            </p>
        </footer>
    )
}

export default Footer
import Layout from "../Components/Layout"
import ExportCard from "../Components/ExportCard"
const Export = () => {
    return (
        <Layout page={"profile"}>
            <ExportCard />
        </Layout>
    )
}

export default Export
import React from 'react'
import BoardLayout from '../Components/BoardLayout'
import Complete from '../Components/Complete'

const OnboardComplete = () => {
  return (
    <div>
        <BoardLayout>
           <Complete />
        </BoardLayout>
    </div>
  )
}

export default OnboardComplete
import BoardLayout from "../Components/BoardLayout"
import WelcomeKyc from "../Components/WelcomeKyc"

const OnboardZero = () => {
    return (
        <BoardLayout>
            <WelcomeKyc />
        </BoardLayout>
    )
}
export default OnboardZero
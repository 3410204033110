import { useState, useEffect } from "react"
import axios from "axios"
// import Dough from "../Admin/Dough"
import AdminLayout from "../Components/AdminLayout"


const AdminDash = () => {
    let id = 0
    const [users, setUsers] = useState(null)
    const [limit, setLimit ]= useState(5)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [dob, setDob] = useState("")

    const [country, setCountry] = useState("")
    const [cardType, setCardType] = useState("")
    const [selfie, setSelfie] = useState("")

    const [cardIssuer, setCardIssuer] = useState("")
    const [cardFront, setCardFront] = useState("")
    const [cardBack, setCardBack] = useState("")

    const [issuerCountry, setIssuerCountry] = useState("")
    const [cardExp, setCardExp] = useState("")

    
    const handleMore = () =>{
        setLimit(limit+5)
    }

    const handleView = async (id) => {
        const config = {
            headers:{
                "Content-Type":"application/json",
                "x-auth-token":localStorage.getItem("x-auth-token")
            }
        };

        try{
            try {

                const {data } = await axios.get("/api/user/"+id, config);

                setFirstName(data.firstName);
                setLastName(data.lastName);
                setEmail(data.email);
                setDob(data.dob);
                
                setCountry(data.country);
                setSelfie(data.selfie);

                setCardType(data.cardType)
                setCardIssuer(data.cardIssuer)

                setCardFront(data.cardFront);
                setCardBack(data.cardBack);

                setIssuerCountry(data.issuerCountry);
                setCardExp(data.cardExp);
            } catch (err) {
                console.log(err);
            }
        }catch(err){
            console.log("Error Check")
        }
    }

    //Verify User
    const verifyUser = async (id) => {

        const config = {
            headers:{
                "Content-Type":"application/json",
                "x-auth-token":localStorage.getItem("x-auth-token")
            }
        };

        const body ={
            status:"approved"
        };

        try {
            const ver = await axios.put("/api/user/"+id, body, config);
            if(ver){
                const newUser = users.filter((user)=>{
                    return user._id !== id;
                });

                setUsers(newUser)

                setFirstName("")
                setLastName("")
                setEmail("")
                setDob("")
                
                setCountry("")
                setSelfie("")

                setCardType("")
                setCardIssuer("")

                setCardFront("")
                setCardBack("")

                setCardExp("")
                
            }
        } catch (err) {
            console.log(err);
        }
    }

    //Decline user
    const declineUser = async (id) => {
        const config = {
            headers:{
                "Content-Type":"application/json",
                "x-auth-token":localStorage.getItem("x-auth-token")
            }
        };

        const body ={
            status:"declined"
        };

        try{
            const ver = await axios.put("/api/user/"+id, body, config);
            if(ver){
                const newUser = users.filter((user)=>{
                    return user._id !== id;
                });

                setUsers(newUser);

                setFirstName("")
                setLastName("")
                setEmail("")
                setDob("")
                
                setCountry("")
                setSelfie("")

                setCardType("")
                setCardIssuer("")

                setCardFront("")
                setCardBack("")

                setCardExp("")
            }
        }catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{
        const getAllUsers = async () =>{
            try {
                const config = {
                    headers:{
                        "Content-Type":"application/json",
                        "x-auth-token":localStorage.getItem("x-auth-token")
                    }
                }
                const {data} = await axios.get(`/api/user/pending/all/${limit}`, config)
                if(data){
                    setUsers(data)
                }
            } catch (error) {
                console.log(error)
            }
        }

        getAllUsers()
    },[limit])


    return (
        <AdminLayout page="dashboard">
            {/* <div className="row">
                <Dough title="Businesses" amt="200" />
                <Dough title="KYC Users" amt="200" />
            </div> */}
            <div className="row">
                <div className="col-md-5">
                    <div className="card">
                        <header className="card-header">
                            <h2 className="h3 card-header-title">All Pending KYC </h2>
                        </header>

                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th className="text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        users && users.map((user)=>(
                                            <tr key={user._id}>
                                                <td>{id=id+1}</td>
                                                <td>{ user.firstName+" "+user.lastName }</td>
                                            
                                            
                                                <td className="text-center">
                                                    <button className="btn btn-sm btn-primary mr-2"onClick={()=>verifyUser(user._id)}>
                                                        <i className="fa fa-check"></i>
                                                    </button>
                                                    <button className="btn btn-sm btn-danger mr-2" onClick={()=>declineUser(user._id)}>
                                                        <i className="fa fa-ban"></i>
                                                    </button>
                                                    <button className="btn btn-sm btn-primary mr-2" onClick={()=>handleView(user._id)} >
                                                        <i className="fa fa-eye"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                
                                </tbody>
                            </table>
                        </div>

                        <div className="p-2">
                            <button className="btn btn-block btn-primary" onClick={ handleMore }> More</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-7 float">
                    <div className="card">
                        <header className="card-header">
                            <h2 className="h3 card-header-title">KYC User Details</h2>
                        </header>
                        <div className="card-body">
                            <div className="row">
                                <div className='col-md-7'>
                                    { (firstName && lastName) && 
                                        <>
                                        <span className='small text-uppercase'>Name</span>
                                        <div style={{fontWeight:600}}>{firstName+" "+lastName}</div>
                                        </>
                                    }
                                    { (email) && 
                                        <>
                                        <span className='small text-uppercase'>Email</span>
                                        <div style={{fontWeight:600}}>{email}</div>
                                        </>
                                    }
                                    {
                                        (dob) &&
                                        <>
                                        <span className='small text-uppercase'>Birthday</span>
                                        <div style={{fontWeight:600}}>{dob}</div>
                                        </>
                                    }
                                    {
                                        (country) &&
                                        <>
                                        <span className='small text-uppercase' >Country</span>
                                        <div style={{fontWeight:600}}>{country}</div>
                                        </>
                                    }
                                    
                                    
                                </div>
                                <div className='col-md-5'>
                                    {
                                        (selfie) && <img src={selfie} alt="passport" width={150} height={150} />
                                    }
                                </div>
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className='col-md-6'>
                                    
                                    { 
                                        cardType && <>
                                        <span className='small text-uppercase'>Card Type</span>
                                        <div style={{fontWeight:600}}>{cardType}</div> 
                                        </>
                                    }                               
                                    
                                </div>
                                
                                <div className='col-md-6'>
                                    
                                    {
                                        (cardIssuer) &&
                                        <>
                                        <span className='small text-uppercase' >ID Number</span>
                                        <div style={{fontWeight:600}}>{cardIssuer}</div>
                                        </>
                                    }                             
                                    
                                </div>
                                <div className='col-md-6'>
                                    
                                    { 
                                        issuerCountry && <>
                                        <span className='small text-uppercase'>Country of Issue</span>
                                        <div style={{fontWeight:600}}>{issuerCountry}</div> 
                                        </>
                                    }                               
                                    
                                </div>

                                <div className='col-md-6'>
                                    
                                    { 
                                        cardExp && <>
                                        <span className='small text-uppercase'>Card Expiration</span>
                                        <div style={{fontWeight:600}}>{cardExp}</div> 
                                        </>
                                    }                               
                                    
                                </div>
                            </div>
                            <hr />
                            <div className='row'>
                                <div className='col-md-6'>
                                    {
                                        (cardFront) && <img src={cardFront} alt="Card Front" className='img-fluid' />
                                    }
                                    <a href={cardFront} target="_blanck" className="btn btn-block btn-primary">View Photo</a>
                                </div>
                                <div className='col-md-6'>
                                    { (cardBack) && <img src={cardBack} alt="Card Front" className='img-fluid' /> }
                                    <a href={cardBack} target="_blanck" className="btn btn-block btn-primary">View Photo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default AdminDash
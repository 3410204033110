import React, { useEffect } from 'react'
import useStore from '../store/useStore'


const Header = () => {
    const user = useStore((state)=>state.user);
    const kycuser = useStore((state)=>state.kycuser);


    const setUser = useStore((state)=>state.setUser);
    const getAllUser = useStore((state)=>state.getAllUser)


    //logout
    const logout = () => {
        localStorage.removeItem("x-auth-token")
        localStorage.removeItem("account")
    }

    let per = (kycuser.total*100) / user.slots;
    


    useEffect(()=>{
        setUser()
        getAllUser()
        
    },[getAllUser, setUser]);

    return (
        <header className="u-header nav-fixed-top">
            <div className="u-header-left">
                <a className="u-header-logo" href="#!">
                    <img 
                        className="u-logo-desktop" 
                        src="../img/logo.svg" 
                        width="160" 
                        alt="Stream Dashboard"
                    />
                    <img 
                        className="img-fluid u-logo-mobile" 
                        src="../img/logo.svg" 
                        width="50" 
                        alt="Stream Dashboard" 
                    />
                </a>
            </div>

            <div className="u-header-middle">
                <a className="js-sidebar-invoker u-sidebar-invoker" 
                    href="#!"
                    data-is-close-all-except-this="true"
                    data-target="#sidebar">
                </a>
            </div>

            <div className="u-header-right mr-4">	
                <div className="dropdown ml-2">
                    <a className="link-muted d-flex align-items-center" 
                        href="#!" role="button" id="dropdownMenuLink" 
                        aria-haspopup="true" aria-expanded="false" 
                        data-toggle="dropdown">
                        <img 
                            className="u-avatar--xs img-fluid rounded-circle mr-2" 
                            src={ user.logo } 
                            alt="User Profile" />
                        <span className="text-dark d-none d-sm-inline-block">
                            {user.name} <small className="fa fa-angle-down text-muted ml-1"></small>
                        </span>
                    </a>

                    <div className="dropdown-menu dropdown-menu-right border-0 py-0 mt-3" 
                        aria-labelledby="dropdownMenuLink" 
                        style={{width: "260px"}}>
                        <div className="card">
                            <div className="card-header py-3">
								<div className="d-flex align-items-center mb-3">
									<span className="h6 text-muted text-uppercase mb-0">KYC Volume</span>

									<div className="ml-auto text-muted">
										<strong className="text-dark">{kycuser.total}</strong> / {user.slots}
									</div>
								</div>

								<div className="progress" style={{height: "4px"}}>
									<div className="progress-bar bg-primary" role="progressbar"  style={{ width: per+"%" }} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
							</div>
                            <div className="card-body">
                                <ul className="list-unstyled mb-0">
                                    <li className="mb-4">
                                        <a className="d-flex align-items-center link-dark" href="/profile">
                                            <span className="h3 mb-0">
                                                <i className="far fa-user-circle text-muted mr-3"></i>
                                                </span> Profile
                                        </a>
                                    </li>

                                    <li className="mb-4">
                                        <a className="d-flex align-items-center link-dark" href="/password">
                                            <span className="h3 mb-0">
                                                <i className="fa fa-lock text-muted mr-3"></i>
                                                </span> Change Password
                                        </a>
                                    </li>
                                                      
                                    <li>
                                        <a className="d-flex align-items-center link-dark" 
                                            onClick={ ()=>logout() }
                                            href="#!">
                                            <span className="h3 mb-0">
                                                <i className="far fa-share-square text-muted mr-3"></i>
                                                </span> Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import useStore from '../store/useStore'

const ProImageCard = () => {

    const user = useStore((state)=>state.user)
    const [logo, setLogo] = useState("")
    const inputRef = useRef(null)

    const uploadClick = () =>{
        inputRef.current.click()
    }

    const changeState = async (e) =>{
        const fileObj = e.target.files && e.target.files[0];
        if (!fileObj) {
            return;
        }

        console.log('fileObj is', fileObj);

        // // 👇️ reset file input
        // e.target.value = null;
        //upload to cloudinary
        //set logo 
        //setLogo(logo)


        try {

            const formData = new FormData();
      
            formData.append("file", fileObj);
            formData.append("upload_preset", "cr5ikfwq");
            formData.append("cloud_name", "depfhww7y");
      
            const { data } = await axios.post(
              "https://api.cloudinary.com/v1_1/depfhww7y/upload",
              formData
            );
            
            //Update database
            const config = {
                headers:{
                    "Content-Type":"application/json",
                    "x-auth-token":localStorage.getItem("x-auth-token")
                }
            }

            const body = {
                logo: data.url
            };

            const upload = await axios.put("http://localhost:5000/api/client/logo", body, config)
            if(upload.data.msg==="success"){
                setLogo(data.url)
            }
          } catch (err) {
            console.log(err);
          }
    }

    useEffect(()=>{
        setLogo(user.logo)
    },[user])
    return (
        <div className="col-md-4 border-md-right border-light text-center">
            <img 
                className="img-fluid rounded-circle mb-3" 
                src={logo} 
                alt="Profile" width="84" 
            />
            <input 
                type="file" 
                id="upload"
                ref={inputRef}
                onChange={changeState}
                hidden
            />

            <h2 className="mb-2">{ user.name}</h2>
            <h5 className="text-muted mb-4">Business Admin</h5>

            <div className="mb-3">
                <button 
                    className="btn btn-primary mb-2" 
                    onClick={uploadClick}>Upload Logo
                </button>
            </div>									
        </div>
    )
}

export default ProImageCard
import React from 'react'
import BoardLayout from '../Components/BoardLayout'
import PersonInfo from '../Components/PersonInfo'

const OnboardOne = () => {
  return (
    <div>
        <BoardLayout>
            <PersonInfo />
        </BoardLayout>
    </div>
  )
}

export default OnboardOne
import React from 'react'
import BoardLayout from '../Components/BoardLayout'
import CardInfo from '../Components/CardInfo'

const OnboardThree = () => {
  return (
    <div>
        <BoardLayout>
            <CardInfo />
        </BoardLayout>
    </div>
  )
}

export default OnboardThree